import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  InputBase,
  Typography,
  Select,
  MenuItem,
  FormControl,
  useTheme,
  useMediaQuery,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Snackbar,
  SnackbarContent,
  TextField,
} from "@mui/material";
import {
  Search,
  Message,
  DarkMode,
  LightMode,
  Notifications,
  Help,
  Menu,
  Close,
  Star,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setMode, setLogout } from "state";
import { useNavigate, Link } from "react-router-dom";
import FlexBetween from "components/FlexBetween";
import Logo from "./favicon.png";
import LogoSeal from "./logo.png";
import { FaEthereum } from "react-icons/fa6";
import '../../index.css';
import axios from 'axios';
import Modal from './Modal';
import { ethers } from "ethers";
import erc20abi from './ERC20abi.json';
import YodaSVG from './Yoda.svg';

// import shibaInuABI from './SHIBAINUabi.json';





import DOI from "./DOI.png"
import Draggable from "react-draggable";
const Navbar = ({ size = "60px" }) => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const [searchQuery, setSearchQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [tokenPurchaseInProgress, setTokenPurchaseInProgress] = useState(false);
  const [contractAddress, setContractAddress] = useState('');
  const [tokenInfo, setTokenInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState(null);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [purchaseAmount, setPurchaseAmount] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [transferAmount, setTransferAmount] = useState('');
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [apiResponse, setApiResponse] = useState('');
  const [responseHistory, setResponseHistory] = useState([]); // State variable to hold response history
  const [previousAngle, setPreviousAngle] = useState(null);
  const [yodaStyle, setYodaStyle] = useState({ left: '0px', top: '0px' });

  useEffect(() => {
    const handleMouseMove = (e) => {
      const yodaElement = document.getElementById('yoda-img');
      if (yodaElement) {
        const rect = yodaElement.getBoundingClientRect();
        const yodaX = rect.left + rect.width / 2;
        const yodaY = rect.top + rect.height / 2;

        // Calculate the angle of rotation based on mouse position relative to Yoda's position
        const angle = Math.atan2(e.clientY - yodaY, e.clientX - yodaX);

        if (previousAngle !== null) {
          // Calculate the change in angle
          let deltaAngle = angle - previousAngle;

          // Normalize the angle to be between -π and π
          if (deltaAngle < -Math.PI) {
            deltaAngle += 2 * Math.PI;
          } else if (deltaAngle > Math.PI) {
            deltaAngle -= 2 * Math.PI;
          }

          // Update the rotation angle
          const rotation = deltaAngle * (180 / Math.PI); // Convert radians to degrees

          // Update the YodaStyle state to reflect the new rotation
          setYodaStyle({
            transform: `rotate(${rotation}deg)`,
            transformOrigin: 'center' // Set the rotation axis to the center of Yoda
          });
        }

        // Update the previous angle
        setPreviousAngle(angle);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [previousAngle]);




  // const shibaInuAddress = '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE';

  // Instantiate the Shiba Inu contract
  // const provider = new ethers.providers.Web3Provider(window.ethereum);
  // const signer = provider.getSigner();
  // const shibaInuContract = new ethers.Contract(shibaInuAddress, shibaInuABI, signer);


  const handleInputChange = (e) => {
    let value = e.target.value;

    // Limit to 187 characters
    if (value.length > 187) {
      value = value.slice(0, 187);
    }


    // Limit line breaks to 3
    const lineBreaks = (value.match(/\n/g) || []).length;
    if (lineBreaks > 3) {
      const lines = value.split('\n');
      value = lines.slice(0, 4).join('\n');
    }


    setSearchQuery(value);
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const lineBreaks = (searchQuery.match(/\n/g) || []).length;
      if (lineBreaks >= 3) {
        e.preventDefault();
      }
    }
  };

  const handleSearchClick = async () => {
    try {
      const response = await axios.post('https://chirpskykite-server.onrender.com/api/generate', { prompt: searchQuery });
      const newResponse = response.data.response;

      // Append the new response to the history
      setResponseHistory(prevHistory => [...prevHistory, newResponse]);

      setApiResponse(newResponse);
      setModalOpen(true);
      setErrorMessage(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error fetching AI response:', error);
      setErrorMessage('Epic fail. Please try again.');
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;
  const dark = theme.palette.neutral.dark;
  const background = theme.palette.background.default;
  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;
  const isMobile = useMediaQuery('(max-width:600px)');

  const fullName = `${user.firstName} ${user.lastName}`;
  const username = `@${user.username}`;


  const handleHelpDialogOpen = () => {
    setIsHelpDialogOpen(true);
  };

  const handleHelpDialogClose = () => {
    setIsHelpDialogOpen(false);
  };






  useEffect(() => {
    // Check if MetaMask is installed and if a wallet is already connected
    const checkWalletConnection = async () => {
      try {
        if (window.ethereum) {
          // Get accounts
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            // Wallet already connected
            setWalletConnected(true);
          }
        }
      } catch (error) {
        console.error('Error checking wallet connection:', error);
      }
    };

    checkWalletConnection();
  }, []);

  const connectWallet = async () => {
    try {
      // Check if MetaMask is installed
      if (window.ethereum) {
        // Request access to the user's MetaMask account
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        // Wallet connected successfully
        console.log('Wallet connected');
        setWalletConnected(true);
      } else {
        // MetaMask is not installed
        console.error('MetaMask is not installed');
      }
    } catch (error) {
      // Error while connecting wallet
      console.error('Error connecting wallet:', error);
    }
  };


  const purchaseTokens = async () => {
    try {
      setTokenPurchaseInProgress(true);

      // Instantiate the ERC-20 contract
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const erc20Contract = new ethers.Contract(contractAddress, erc20abi, signer);

      const depositAmount = "";

      // Convert depositAmount to wei
      const depositAmountInWei = ethers.utils.parseEther(depositAmount.toString());

      // Send transaction to the contract's deposit function
      const transaction = await erc20Contract.deposit(depositAmountInWei, {
        value: depositAmountInWei, // Sending Ether along with the transaction
        gasLimit: 300000,
      });

      // Wait for transaction to be confirmed
      await transaction.wait();

      // Update balance
      const balance = await erc20Contract.balanceOf(await signer.getAddress());
      setBalanceInfo({ balance: balance.toString() });

      console.log('Tokens purchased successfully');
      setSuccessSnackbarOpen(true);
    } catch (error) {
      console.error('Error purchasing tokens:', error);
      setErrorSnackbarOpen(true);
    } finally {
      setTokenPurchaseInProgress(false);
      setShowPurchaseModal(false);
    }
  };


  const getTokenInfo = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const erc20Contract = new ethers.Contract(contractAddress, erc20abi, provider);
      const name = await erc20Contract.name();
      const symbol = await erc20Contract.symbol();
      const totalSupply = await erc20Contract.totalSupply();
      setTokenInfo({ name, symbol, totalSupply: totalSupply.toString() });
    } catch (error) {
      console.error('Error fetching token info:', error);
    } finally {
      setLoading(false);
    }
  };


  // getTokenBalance function
  const getTokenBalance = async () => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const erc20Contract = new ethers.Contract(contractAddress, erc20abi, provider);
      const balance = await erc20Contract.balanceOf(provider.getSigner().getAddress());
      setBalanceInfo({ balance: balance.toString() });
    } catch (error) {
      console.error('Error fetching token balance:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle input change for recipient address
  const handleRecipientAddressChange = (event) => {
    setRecipientAddress(event.target.value);
  };

  // Function to handle input change for transfer amount
  const handleTransferAmountChange = (event) => {
    setTransferAmount(event.target.value);
  };


  // Transfer tokens function
  const transferTokens = async (recipientAddress, amount) => {
    try {
      setLoading(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Instantiate the ERC-20 contract
      const erc20Contract = new ethers.Contract(contractAddress, erc20abi, signer);

      // Check allowance
      const allowance = await erc20Contract.allowance(signer.getAddress(), contractAddress);
      if (allowance.lt(amount)) {
        // If allowance is not enough, approve spending
        const approveTx = await erc20Contract.approve(contractAddress, amount);
        await approveTx.wait();
      }

      // Transfer tokens
      const transferTx = await erc20Contract.transfer(recipientAddress, amount);
      await transferTx.wait();

      // Update balance
      const balance = await erc20Contract.balanceOf(signer.getAddress());
      setBalanceInfo({ balance: balance.toString() });

      console.log('Tokens transferred successfully!');
    } catch (error) {
      console.error('Error transferring tokens:', error);
    } finally {
      setLoading(false);
    }
  };


  //   // Function to get token name
  // const getTokenName = async () => {
  //   try {
  //     const name = await shibaInuContract.name();
  //     console.log('Token Name:', name);
  //   } catch (error) {
  //     console.error('Error getting token name:', error);
  //   }
  // };

  // // Function to get token symbol
  // const getTokenSymbol = async () => {
  //   try {
  //     const symbol = await shibaInuContract.symbol();
  //     console.log('Token Symbol:', symbol);
  //   } catch (error) {
  //     console.error('Error getting token symbol:', error);
  //   }
  // };

  // // Function to get total token supply
  // const getTotalSupply = async () => {
  //   try {
  //     const totalSupply = await shibaInuContract.totalSupply();
  //     console.log('Total Supply:', totalSupply.toString());
  //   } catch (error) {
  //     console.error('Error getting total supply:', error);
  //   }
  // };

  // // Function to buy tokens
  // const buyTokens = async (amount) => {
  //   try {
  //     // Call transfer function to buy tokens
  //     const transaction = await shibaInuContract.transfer(signer.getAddress(), amount);
  //     await transaction.wait();
  //     console.log('Tokens bought successfully');
  //   } catch (error) {
  //     console.error('Error buying tokens:', error);
  //   }
  // };

  // // Function to send tokens to another address
  // const sendTokens = async (recipient, amount) => {
  //   try {
  //     // Call transfer function to send tokens
  //     const transaction = await shibaInuContract.transfer(recipient, amount);
  //     await transaction.wait();
  //     console.log('Tokens sent successfully');
  //   } catch (error) {
  //     console.error('Error sending tokens:', error);
  //   }
  // };

  // // Example usage
  // getTokenName();
  // getTokenSymbol();
  // getTotalSupply();
  // // Call buyTokens or sendTokens functions as needed



  return (
    <FlexBetween padding="1rem 6%" backgroundColor={alt}>
      <FlexBetween gap="1.75rem">
        <img src={Logo} alt="Chirpsky Logo" style={{ height: '40px', marginRight: '10px' }} />
        <Typography
          fontWeight="bold"
          fontSize="clamp(1rem, 2rem, 2.25rem)"
          color="#8a0303"
          onClick={() => navigate("/home")}
          sx={{
            "&:hover": {
              color: "skyblue",
              cursor: "pointer",
            },
          }}
          style={{ marginLeft: '-33px' }}
        >
          Chirpsky
        </Typography>
        {isNonMobileScreens && (
          <FlexBetween
            backgroundColor={neutralLight}
            borderRadius="9px"
            gap="3rem"
            padding="0.1rem 1.5rem"
          >
            <InputBase

              placeholder="Ask me anything..."
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              // onChange={(e) => setSearchQuery(e.target.value)}
              multiline
              maxRows={6}
              inputProps={{ maxLength: 187 }}
              style={{ whiteSpace: 'pre-wrap', width: '200px' }}
            />
            <IconButton
              disabled={!searchQuery.trim()}
              onClick={handleSearchClick}
            >
              <Search />
            </IconButton>




          </FlexBetween>
        )}
        <Modal
          open={modalOpen}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          responseHistory={responseHistory} // Pass the response history to the Modal component
          sx={{
            display: 'flex',
            justifyContent: 'center', // Center the modal
            alignItems: 'center', // Center the modal
            width: '100%',
            maxHeight: "100vh",
            overflow: 'hidden', // Ensure modal doesn't overflow the viewport
          }}
        >
          <Box
            p={2}
            backgroundColor="black"
            borderRadius={1}
            width={isMobile ? '90%' : '420px'}
            maxHeight="100vh" // Ensures the modal does not exceed 90% of viewport height
            overflowY="auto" // Enables vertical scrolling if content exceeds maxHeight
            textAlign="center"
            position="relative"
            top={isMobile ? '-10%' : '-35px'}
            left={isMobile ? '-5%' : '-40px'}
          >
            <Typography id="modal-title" variant="h3" component="h2" color="#8a0303" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
              ChirpskyR2D2
            </Typography>
            <img id="yoda-img" src={YodaSVG} alt="Yoda" width="100" height="100" style={yodaStyle} />

            <Typography
              id="modal-description"
              sx={{
                color: '#003b00',
                whiteSpace: 'pre-wrap',
                fontSize: '1.2rem',
                textAlign: 'left',
                userSelect: 'text',
                overflowY: 'auto', // Ensure text content can scroll if it overflows
                maxHeight: '25vh' // Subtracts some fixed height to ensure space for other elements in the box
              }}
            >
              {apiResponse}
            </Typography>
            {/* Input field and send button */}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              <TextField
                id="user-input"
                label="*Help Begins with Asking.*"
                variant="outlined"
                fullWidth
                inputProps={{ style: { color: '#8a0303', fontSize: '21px', whiteSpace: 'pre-wrap' }, maxLength: 187 }}
                multiline
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                InputLabelProps={{
                  sx: {
                    color: '#8a0303',
                    fontSize: '20px',
                    backgroundColor: '#00ff41',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    textTransform: 'uppercase',
                    cursor: 'pointer'
                  }
                }}
              />

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!searchQuery.trim()}
                  onClick={handleSearchClick}
                  sx={{ padding: '6px 12px', mr: '-10px' }} // Use a larger negative margin
                >
                  Skys The Limit
                </Button>

              </Box>
              {/* Error message */}
              <Box sx={{ mt: 1 }}>
                {errorMessage && (
                  <Typography sx={{ color: 'red', fontSize: '1.2rem' }}>Error: {errorMessage}</Typography>
                )}
              </Box>
              <Box>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="primary"
                  className="custom-exit-button custom-exit-button-contained"
                >
                  Exit
                </Button>
              </Box>

            </Box>
          </Box>
        </Modal>












        <div>
          <button
            style={{
              backgroundColor: isHovered ? "skyblue" : "#8a0303",
              border: "none",
              color: isHovered ? "#8a0303" : "white",
              textDecoration: "none",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "25px",
              transition: "background-color 0.3s" // Optional: Add a smooth transition
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <a
              className="buy-with-crypto"
              href="https://commerce.coinbase.com/checkout/e752136e-9b94-4a2a-b0a7-7229605833c5"
              target="_blank"
              rel="noopener"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontSize: "12px",
                backgroundColor: isHovered ? "skyblue" : "transparent",
                display: "inline-block",
              }}
            >
              Contribute Crypto
            </a>
          </button>
          <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807"></script>
        </div>

        {isNonMobileScreens && ( // Render the "Connect Wallet" button only on non-mobile screens
          <button
            className="connect-wallet"
            style={{
              backgroundColor: isHovered ? "skyblue" : "#8a0303",
              border: "none",
              color: isHovered ? "#8a0303" : "white",
              textDecoration: "none",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "25px",
              transition: "background-color 0.3s"
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={walletConnected ? () => setShowPurchaseModal(true) : connectWallet}
            disabled={tokenPurchaseInProgress}
          >
            {tokenPurchaseInProgress ? 'Purchasing Tokens...' : (walletConnected ? 'Purchase Tokens' : 'Connect Wallet')}
          </button>



        )}
        <Modal open={showPurchaseModal} onClose={() => setShowPurchaseModal(false)}>


          <div className="action-button-wrapper">
            <button onClick={getTokenInfo} className="action-button">Get Token Info</button>
            <div className="modal-addr-content">
              <div className="input-dark-wrapper-addr">

                <input
                  type="text"
                  value={contractAddress}
                  onChange={(e) => setContractAddress(e.target.value)}
                  placeholder="Enter ERC-20 Contract Address"
                  className="input-dark-ERC20"
                  maxLength={69}
                />
              </div>


              {loading && <p>Loading...</p>}
              {tokenInfo && (
                <div className="token-info">
                  <p><span className="token-info-span">Name:</span> {tokenInfo.name}</p>
                  <p><span className="token-info-span">Symbol:</span> {tokenInfo.symbol}</p>
                  <p><span className="token-info-span">Total Supply:</span> {tokenInfo.totalSupply}</p>
                </div>

              )}
            </div>

            <div className="purchaseToken-btn" >
              <button
                onClick={purchaseTokens}
                disabled={tokenPurchaseInProgress || !purchaseAmount}
                className="purchaseToken-btn-buy"
              >
                {tokenPurchaseInProgress ? 'Purchasing Tokens...' : 'Invest In Chirpsky'}
              </button>
            </div>


            <div class="input-dark-wrapper">
              <input
                className="input-dark-purchase-btn"
                type="text"
                value={purchaseAmount}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // Validate the entered value to allow only numbers and optional decimal point
                  if (/^\d*\.?\d*$/.test(newValue) && newValue.length <= 26) {
                    setPurchaseAmount(newValue);
                  }
                }}
                placeholder="ChirpskyX (Enter ETH Amount)"
                inputMode="numeric" // Enable numeric input mode
                onKeyDown={(e) => {
                  // Allow only numeric input by preventing the default action for non-numeric key presses
                  if (!(e.key === 'Enter' || (e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === '.')) {
                    e.preventDefault();
                  }

                  // Check if the value is already zero and the down arrow key is pressed
                  if (Number(purchaseAmount) === 0 && e.key === 'ArrowDown') {
                    e.preventDefault(); // Prevent the default behavior to allow decreasing below zero
                  }
                }}
                maxLength={26} // Limit maximum length to prevent entering larger numbers
              />
              <div className="action-balance-container">
                <button onClick={getTokenBalance} className="action-balance-button">Get Token Balance</button>
                {balanceInfo && (
                  <div className="balance-info">
                    <p><span className="balance-info-span">Balance:</span> {balanceInfo.balance}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-balance-content">
              <div className="input-dark-wrapper-balance">
                {loading && <p>Loading...</p>}
                {balanceInfo && (
                  <div className="balance-info-set">
                    <p><span className="balance-info-span-set"></span> {setBalanceInfo}</p>
                  </div>
                )}


              </div>

            </div>



          </div>
          <div className="transfer-container">
            <button className="transfer-btn" onClick={() => transferTokens(recipientAddress, transferAmount)} disabled={loading}>Send Crypto</button>

            <div className="transfer-inputs">

              <input
                className="transfer-input-recipient"
                type="text"
                value={recipientAddress}
                onChange={handleRecipientAddressChange}
                placeholder="Recipient Address"
                maxLength={69}
              />


              <input
                className="transfer-input-amount"
                type="number"
                value={transferAmount}
                onChange={(e) => {
                  const newValue = e.target.value;
                  // Ensure that the value contains only numeric characters
                  const numericValue = newValue.replace(/\D/g, ''); // Remove non-numeric characters
                  // Check if the value is within the range of 0 to 9999999999999
                  if (numericValue === '' || (parseInt(numericValue) >= 0 && parseInt(numericValue) <= 99999999999999999999999)) {
                    setTransferAmount(numericValue);
                  }
                }}
                placeholder="Amount (10^¹⁸) = 1 CHRSX"
                inputMode="numeric" // Enable numeric input mode
                onKeyDown={(e) => {
                  // Allow only numeric input and specific key presses
                  if (!(e.key === 'Enter' || (e.key >= '0' && e.key <= '9') || e.key === 'Backspace' || e.key === 'Delete' || e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight')) {
                    e.preventDefault();
                  }

                  // Check if the value is already zero and the down arrow key is pressed
                  if (transferAmount === '0' && e.key === 'ArrowDown') {
                    e.preventDefault(); // Prevent the default behavior to allow decreasing below zero
                  }
                }}
                min={0}
                max={99999999999999999999999} // Maximum allowed value to prevent entering larger numbers
              />

            </div>

          </div>

        </Modal>



        <Snackbar
          open={successSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSuccessSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        >
          <SnackbarContent
            style={{ backgroundColor: 'green' }}
            message="Tokens purchased successfully"
            action={
              <Button color="secondary" size="small" onClick={() => setSuccessSnackbarOpen(false)}>
                Close
              </Button>
            }
          />
        </Snackbar>


        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        >
          <SnackbarContent
            style={{ backgroundColor: 'red' }}
            message="Error purchasing tokens"
            action={
              <Button color="secondary" size="small" onClick={() => setErrorSnackbarOpen(false)}>
                Close
              </Button>
            }
          />
        </Snackbar>

      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap="2rem">
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <DarkMode sx={{ fontSize: "25px" }} />
            ) : (
              <LightMode sx={{ color: dark, fontSize: "25px" }} />
            )}
          </IconButton>
          <a href="https://www.chirpskyXChange.net" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Tooltip title="ChirpskyXChange ♦">
              <IconButton>
                <FaEthereum sx={{ fontSize: "25px" }} />
              </IconButton>
            </Tooltip>
          </a>
          <a href="https://www.chirpskykite.net" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Tooltip title="ChirpskyKite">
              <IconButton>
                <Message sx={{ fontSize: "25px" }} />
              </IconButton>
            </Tooltip>
          </a>
          <Notifications sx={{ fontSize: "25px" }} />
          <Tooltip title="Koming Soon"> <Help sx={{ fontSize: "25px", cursor: "pointer" }} onClick={handleHelpDialogOpen} /></Tooltip>
          <FormControl variant="standard" value={username}>
            <Select
              value={username}
              sx={{
                color: "red",
                backgroundColor: neutralLight,
                width: "150px",
                borderRadius: "0.25rem",
                p: "0.25rem 1rem",
                "& .MuiSvgIcon-root": {
                  pr: "0.25rem",
                  width: "3rem",
                },
                "& .MuiSelect-select:focus": {
                  backgroundColor: neutralLight,
                },
              }}
              input={<InputBase />}
            >
              <MenuItem value={username} sx={{ color: "green" }}>
                <Typography sx={{ fontSize: "1.5rem" }}>{username}</Typography>
              </MenuItem>
              <MenuItem>

                <Link to="https://chirpkingpins.netlify.app/" target="_blank" style={{ textDecoration: 'none' }}>
                  <span style={{ color: 'red', marginRight: "0.5rem", fontSize: "1.5rem" }}>King</span>
                  <span style={{ color: 'green', fontSize: "1.5rem" }}>Pins</span>
                </Link>
              </MenuItem>

              {/* <MenuItem>

                <Link to="https://www.chirpskyx.net/" target="_blank" style={{ textDecoration: 'none' }}>
                  <span style={{ color: '#8a0303', marginRight: "0", fontSize: "1.5rem" }}>Chirpsky</span>
                  <span style={{ color: '#1DA1F2', fontSize: "1.8rem" }}>X</span>
                </Link>
              </MenuItem> */}
              <MenuItem>
                <button
                  className="connect-wallet"
                  style={{
                    backgroundColor: isHovered ? "skyblue" : "#8a0303",
                    border: "none",
                    color: isHovered ? "#8a0303" : "white",
                    textDecoration: "none",
                    padding: "10px 20px",
                    cursor: "pointer",
                    borderRadius: "25px",
                    transition: "background-color 0.3s" // Optional: Add a smooth transition
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => {
                    if (walletConnected) {
                      setShowPurchaseModal(true); // Open the purchase modal if wallet is connected
                    } else {
                      connectWallet(); // Otherwise, trigger the connectWallet function
                    }
                  }}
                >
                  {walletConnected ? 'MetaMusk Linked' : 'Connect Wallet'}
                </button>
              </MenuItem>

              <MenuItem sx={{ fontSize: "1.2rem" }} onClick={() => dispatch(setLogout())}>Log Out</MenuItem>
            </Select>
          </FormControl>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="300px"
          minWidth="300px"
          backgroundColor={background}
          onClick={(e) => {
            // Check if the click event originates from the close (X) button
            if (!e.target.closest("#mobile-nav-close")) {
              return; // Do nothing if it doesn't originate from the close button
            }
            setIsMobileMenuToggled(false); // Close the mobile menu if the close button is clicked
          }}
        >
          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton id="mobile-nav-close" onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}>
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <IconButton
              onClick={() => dispatch(setMode())}
              sx={{ fontSize: "25px" }}
            >
              {theme.palette.mode === "dark" ? (
                <DarkMode sx={{ fontSize: "25px" }} />
              ) : (
                <LightMode sx={{ color: dark, fontSize: "25px" }} />
              )}
            </IconButton>
            <a href="https://www.chirpskyXChange.net" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Tooltip title="ChirpskyXChange ♦">
                <IconButton>
                  <FaEthereum sx={{ fontSize: "25px" }} />
                </IconButton>
              </Tooltip>
            </a>
            <a href="https://www.chirpskykite.net" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Tooltip title="ChirpskyKite">
                <IconButton>
                  <Message sx={{ fontSize: "25px" }} />
                </IconButton>
              </Tooltip>
            </a>
            <Notifications sx={{ fontSize: "25px" }} />
            <Tooltip title="Koming Soon"> <Help sx={{ fontSize: "25px", cursor: "pointer" }} onClick={handleHelpDialogOpen} /></Tooltip>

            {/* AI Search Input */}
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <TextField
                id="user-input"
                label="Ask me anything"
                variant="outlined"
                fullWidth
                inputProps={{ style: { color: '#8a0303', fontSize: '21px', whiteSpace: 'pre-wrap' }, maxLength: 187 }}
                multiline
                value={searchQuery}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                InputLabelProps={{ sx: { color: '#8a0303', fontSize: '21px', backgroundColor: "#00ff41" } }}
              />

              <Button
                variant="contained"
                color="primary"
                disabled={!searchQuery.trim()}
                onClick={handleSearchClick}
                sx={{ mt: 2 }}>
                Skys The Limit
              </Button>
              {errorMessage && (
                <p style={{ color: 'red', fontSize: '1.2rem' }}>Error: {errorMessage}</p>
              )}
            </Box>


            <FormControl variant="standard" value={username}>
              <Select
                value={username}
                sx={{
                  backgroundColor: neutralLight,
                  width: "150px",
                  borderRadius: "0.25rem",
                  p: "0.25rem 1rem",
                  "& .MuiSvgIcon-root": {
                    pr: "0.25rem",
                    width: "3rem",
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: neutralLight,
                  },
                }}
                input={<InputBase />}
              >
                <MenuItem value={username}>
                  <Typography sx={{ color: "red", fontSize: "1.5rem" }}>{username}</Typography>
                </MenuItem>

                <MenuItem>

                  <Link to="https://chirpkingpins.netlify.app/" target="_blank" style={{ textDecoration: 'none' }}>
                    <span style={{ color: 'red', marginRight: "0.5rem", fontSize: "1.5rem" }}>King</span>
                    <span style={{ color: 'green', fontSize: "1.5rem" }}>Pins</span>
                  </Link>
                </MenuItem>


                {/* <MenuItem>

                  <Link to="https://www.chirpskyx.net/" target="_blank" style={{ textDecoration: 'none' }}>
                    <span style={{ color: '#1DA1F2', marginRight: "0", fontSize: "1.5rem" }}>Chirpsky</span>
                    <span style={{ color: '#8a0303', fontSize: "1.8rem" }}>X</span>
                  </Link>
                </MenuItem> */}
                <MenuItem>
                  <button
                    className="connect-wallet"
                    style={{
                      backgroundColor: isHovered ? "skyblue" : "#8a0303",
                      border: "none",
                      color: isHovered ? "#8a0303" : "white",
                      textDecoration: "none",
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderRadius: "25px",
                      transition: "background-color 0.3s" // Optional: Add a smooth transition
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={walletConnected ? () => setShowPurchaseModal(true) : connectWallet}
                  >
                    {walletConnected ? 'MetaMusk Linked' : 'Connect Wallet'}
                  </button>
                </MenuItem>
                <MenuItem sx={{ fontSize: "1.2rem" }} onClick={() => dispatch(setLogout())}>
                  Log Out
                </MenuItem>
              </Select>
            </FormControl>


          </FlexBetween>


        </Box>


      )}


      {/* HELP DIALOG */}
      <Dialog open={isHelpDialogOpen} onClose={handleHelpDialogClose} maxWidth="md" fullWidth>
        <DialogTitle style={{ textAlign: "center", color: "#1DA1F2", fontFamily: 'MuRdOiNk' }}>
          {/* title */}
          Welcome to Chirpsky
        </DialogTitle>
        <DialogContent>
          Please make yourselves at home, while many features are still being built, you could expect some things might not function as intended, but rest assured... if there is a demand for certain features, and what might you like to have in a social networking platform, please leave a post or comment of what you want. I truly care about more than myself.
          <Box
            sx={{
              backgroundImage: `url(${DOI})`,
              backgroundSize: "cover",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              textAlign: "center",
              minHeight: "500px",
              position: "relative", // Make the position relative
            }}

          >
            <div
              style={{
                position: "absolute",
                top: "50%", // Adjust these values for logo positioning
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "170px", // Adjust the width of the logo
                height: "170px", // Adjust the height of the logo
                backgroundImage: `url(${LogoSeal})`, // Replace with the path to your logo
                backgroundSize: "cover",
                zIndex: 1,
              }}
            />  <Typography mt={33} variant="h5" style={{ textAlign: "center", color: "red", fontSize: "16px" }}>"Government is not reason, it is not eloquence, it is force; like fire, a troublesome servant and a fearful master."
              "Happiness and moral duty are inseparably connected." - George Washington</Typography>

          </Box>


          <Typography variant="h5" style={{ textAlign: "center", color: "#1DA1F2", zIndex: 2 }}>Freedom is Just. Freedom is Just. Freedom is Just a click away.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHelpDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </FlexBetween>

  );
};

export default Navbar;