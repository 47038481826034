import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "scenes/navbar";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import MyPostWidget from "scenes/widgets/MyPostWidget";
import PostsWidget from "scenes/widgets/PostsWidget";
import UserWidget from "scenes/widgets/UserWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import ScrollToTopButton from "components/ScrollToTopButton";
import Declassified from "scenes/widgets/Declassified";


const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const { userId } = useParams();
  const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const getUser = async () => {
    const response = await fetch(`https://chirpskykite-server.onrender.com/users/${userId}`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    setUser(data);
  };

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) return null;

  return (
    <Box>
      <Navbar />
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"

      >
        <Box flexBasis={isNonMobileScreens ? "26%" : "100%"} maxWidth={isNonMobileScreens ? "26%" : "100%"} marginRight={isNonMobileScreens ? "2rem" : "0"}>
          <Declassified />
        </Box>
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined} marginTop="2rem">
          <UserWidget userId={userId} picturePath={user.picturePath} />
          <Box m="2rem 0" />
          <Box marginBottom="2rem">
            <FriendListWidget
              userId={userId}
              maxHeight="600px"
              overflowY="scroll"
            />
          </Box>
          <Box marginBottom="2rem">
            <AdvertWidget />
          </Box>
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          <MyPostWidget picturePath={user.picturePath} />
          <Box m="2rem 0" />
          <PostsWidget userId={userId} isProfile />
          <Link to={`/profile/${userId}`}></Link>
        </Box>
      </Box>
      <Box
        width="100%"
        padding="2rem 6%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="2rem"
        justifyContent="center"
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
          {/* <UserWidget userId={userId}/> */}
          <Box m="2rem 0" />
          {/* <FriendListWidget userId={userId} /> */}
        </Box>
        <Box
          flexBasis={isNonMobileScreens ? "42%" : undefined}
          mt={isNonMobileScreens ? undefined : "2rem"}
        >
          {/* <MyPostWidget videoPath={user.videoPath}/>
          <Box m="2rem 0" />
          <PostsWidget userId={userId} isProfile /> */}
        </Box>
      </Box>



      <ScrollToTopButton />
    </Box>
  );
};

export default ProfilePage;