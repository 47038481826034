import { Divider, Typography, useTheme } from "@mui/material";
import Icons from './Icons';
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";

const AdvertWidget = () => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (
    <WidgetWrapper style={{ height: '400px', overflowY: 'auto' }}>

<FlexBetween >
        
        <Typography 
        sx={{ml: "80px !important"}}
        color="#8a0303" variant="h5" fontWeight="500"  style={{ textAlign: "center !important" }}>
          Candace Corner
        </Typography>
      </FlexBetween>
      <iframe
  width="100%"
  height="auto"
  src="https://www.youtube.com/embed/UQxNSV32Zkc"
  title="YouTube Video"
  style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
  allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
        <Typography color={main} style={{ marginRight: "1rem" }}>
          Candace Owens
        </Typography>
      
        <a
          href="https://www.patreon.com/candaceowens"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: medium }}
        >
          https://www.youtube.com/@RealCandaceO
        </a>
      </div>
      <Typography color={medium} m="0.5rem 0"> “I sometimes wonder if we so often seek to point out ugliness as a cheap formulaic way to convince ourselves that we are good.” ― Candace Owens, Blackout: How Black America Can Make Its Second Escape from the Democrat Plantation 
      </Typography>
      <Divider />

      <FlexBetween >
        
        <Typography 
        sx={{ml: "80px !important"}}
        color="#8a0303" variant="h5" fontWeight="500"  style={{ textAlign: "center !important" }}>
          Current Events
        </Typography>
      </FlexBetween>
      <iframe
  width="100%"
  height="auto"
  src="https://www.youtube.com/embed/nsDZomjhJ_Y"
  title="YouTube Video"
  style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
  allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
        <Typography color={main} style={{ marginRight: "1rem" }}>
          Stephen Gardner
        </Typography>
      
        <a
          href="https://www.yourbridgeplan.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: medium }}
        >
          www.yourbridgeplan.com
        </a>
      </div>
      <Typography color={medium} m="0.5rem 0">
        I bring you one of the only news sources i tune into.
      </Typography>
      <Divider />

      <FlexBetween >
        <Typography 
        sx={{ml: "80px !important"}}
        color="#8a0303" variant="h5" fontWeight="500"  style={{ textAlign: "center !important" }}>
          Idiocracy
        </Typography>
      </FlexBetween>
      <iframe
  width="100%"
  height="auto"
  src="https://www.youtube.com/embed/IvxQm4Djsho"
  title="YouTube Video"
  style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
  allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
        <Typography color={main} style={{ marginRight: "1rem" }}>
          Joey B Toonz
        </Typography>
      
        <a
          href="https://www.patreon.com/joeybtoonz"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: medium }}
        >
          www.patreon.com/joeybtoonz
        </a>
      </div>
      <Typography color={medium} m="0.5rem 0">
      The world is like a Rubik's Cube – twisted, confusing, and everyone's trying to figure out how to make it right without breaking it further
      </Typography>
      <Divider />




      <FlexBetween >
        <Typography 
        sx={{ml: "80px !important"}}
        color="#8a0303" variant="h5" fontWeight="500"  style={{ textAlign: "center !important" }}>
          Anonymous
        </Typography>
      </FlexBetween>
      <iframe
  width="100%"
  height="auto"
  src="https://www.youtube.com/embed/KN2zPsnoQbs"
  title="YouTube Video"
  style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
  allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
      <Icons />
        <Typography color={main} style={{ marginRight: "2rem", textAlign: "center" }}>
          V For Vendetta
        </Typography>
      
        <a
          href="https://www.youtube.com/@-anonymous"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: medium }}
        >
          Anonymous Official
        </a>
      </div>
      <Typography color={medium} m="0.5rem 0">
      Wisdom is divided into two parts: (a) having a great deal to say, and (b) not saying it.
      </Typography>
      <Divider />

      <FlexBetween >
        <Typography 
        sx={{ml: "80px !important"}}
        color="#8a0303" variant="h5" fontWeight="500"  style={{ textAlign: "center !important" }}>
          Gerald Celente
        </Typography>
      </FlexBetween>
      <iframe
  width="100%"
  height="auto"
  src="https://www.youtube.com/embed/wqv3ilXSPjQ"
  title="YouTube Video"
  style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
  allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
        <Typography color={main} style={{ marginRight: "1rem" }}>
        👑 
        </Typography>
      
        <a
          href="https://trendsjournal.com/subscribe/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: medium }}
        >
          https://trendsjournal.com/
        </a>
      </div>
      <Typography color={medium} m="0.5rem 0">
      PIONEER TREND STRATEGIST Gerald Celente is the Founder/Director of the Trends Research Institute and Publisher of the weekly Trends Journal magazine. He is the author of the highly acclaimed and best-selling books “Trend Tracking” and “Trends 2000” (Warner Books).
      </Typography>

      <Divider/>

      <FlexBetween >
        <Typography 
        sx={{ml: "80px !important"}}
        color="#8a0303" variant="h5" fontWeight="500"  style={{ textAlign: "center !important" }}>
          Tucker Carlson
        </Typography>
      </FlexBetween>
      <iframe
  width="100%"
  height="auto"
  src="https://www.youtube.com/embed/fOCWBhuDdDo"
  title="YouTube Video"
  style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
  allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
        <Typography color={main} style={{ marginRight: "1rem" }}>
          TCN 
        </Typography>
      
        <a
          href="https://tuckercarlson.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: medium }}
        >
          https://tuckercarlson.com/
        </a>
      </div>
      <Typography color={medium} m="0.5rem 0">
       "Advocate Away" - Tucker Carlson
      </Typography>



      <Divider/>

<FlexBetween >
  <Typography 
  sx={{ml: "80px !important"}}
  color="#8a0303" variant="h3" fontWeight="500"  style={{ textAlign: "center !important" }}>
    Audio Books
  </Typography>
</FlexBetween>
<iframe
width="100%"
height="auto"
src="https://www.youtube.com/embed/zY78xiw4thY"
title="YouTube Video"
style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
  <Typography color={main} style={{ marginRight: "1rem" }}>
    Milton William Cooper 
  </Typography>

  <a
    href="https://www.youtube.com/watch?v=8fmppggRoyk"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: medium }}
  >
    https://www.youtube.com/watch?v=8fmppggRoyk/
  </a>
</div>
<Typography color={medium} m="0.5rem 0">
“All that is necessary for evil to triumph is for good men to do nothing.“ - MWC
</Typography>


<Divider/>

<FlexBetween >
  <Typography 
  sx={{ml: "80px !important"}}
  color="#8a0303" variant="h3" fontWeight="500"  style={{ textAlign: "center !important" }}>
    Permanent Record
  </Typography>
</FlexBetween>
<iframe
width="100%"
height="auto"
src="https://www.youtube.com/embed/MI5xj5XshVg"
title="YouTube Video"
style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
allowFullScreen
/>
<div style={{ display: "flex", alignItems: "center" }}>
  <Typography color={main} style={{ marginRight: "1rem" }}>
    Edward Snowden
  </Typography>

  <a
    href="https://www.youtube.com/watch?v=NRzDsxUOm4M"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: medium }}
  >
    Citizenfour
  </a>
</div>
<Typography color={medium} m="0.5rem 0">
“Ultimately, arguing that you don't care about the right to privacy because you have nothing to hide is no different than saying you don't care about free speech because you have nothing to say.” - ES
</Typography>
      
      

      
      
    </WidgetWrapper>
  );
};

export default AdvertWidget;