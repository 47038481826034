import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';

const Modal = ({ open, onClose, responseHistory, children }) => {

    const [width, setWidth] = useState(200);
    const [height, setHeight] = useState(300);
    const [minimized, setMinimized] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1); 
    useEffect(() => {
        if (open) {
            const initialPosition = {
                x: window.innerWidth / 2 - width / 2,
                y: window.innerHeight * 0.1, // 10% from the top
            };
            setPosition(initialPosition);
        }
    }, [open, width, height]);

    if (!open) return null;

    const handleClose = () => {
        onClose();
    };

    const handleResize = (event, { size }) => {
        setWidth(size.width);
        setHeight(size.height);
    };

    const handleMinimize = () => {
        setMinimized(!minimized);
        setPosition({ x: window.innerWidth - width - 20, y: window.innerHeight - height - 20 });
    };

    const handleRestore = () => {
        setMinimized(false);
        setPosition({
            x: window.innerWidth / 2 - width / 2,
            y: window.innerHeight * 0.1, // 10% from the top
        });
    };

    const handleDrag = (e, ui) => {
        setPosition({ x: ui.x, y: ui.y }); // Update position while dragging
    };


    const handleWheel = (event) => {
        if (event.ctrlKey) {
            event.preventDefault(); // Prevent default scrolling behavior
            const delta = event.deltaY > 0 ? 0.1 : -0.1; // 
            const newScale = Math.max(0.5, Math.min(scale + delta, 2)); // Limit scale between 0.5 and 2
            setScale(newScale);
        }
    };


    // const modalStyle = {
    //     position: 'fixed',
    //     zIndex: 1,
    //     width: minimized ? '200px' : '460px',
    //     height: minimized ? '40px' : '600px',
    //     maxHeight: '60vh',
    //     overflowY: 'scroll', 
    //     backgroundColor: 'rgba(0, 0, 0, 0.4)',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     bottom: minimized ? '0' : '600px',
    //     right: minimized ? '0' : 'auto',
    //     left: minimized ? 'auto' : position.x,
    //     top: minimized ? 'auto' : position.y,
    //     transition: 'left 0.3s, top 0.3s',
    // };


    

    return (
        <>
            {open && (
                <Draggable  handle=".modal-header">
                    
                    <Resizable
                        width={width}
                        height={height}
                        onResize={handleResize}
                        minConstraints={[200, 200]}
                        maxConstraints={[500, 600]}
                    >
                       
                        <div className={`modal ${minimized ? 'minimized' : ''}`}  onWheel={handleWheel}>
                            <div className="modal-header" onDoubleClick={handleRestore}>
                                <span className={`minimize ${minimized ? 'maximized' : ''}`} onClick={handleMinimize}>-</span>
                                <span className="close" onClick={onClose}>&times;</span>
                                <div className="drag-handle" />
                            </div>
                            <div className="modal-content">
                                {children}
                            </div>
                        </div>
                    </Resizable>
                </Draggable>
            )}
        </>
    );
};

export default Modal;
