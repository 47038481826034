import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Button, Tooltip, useMediaQuery, Stack } from "@mui/material";
import WidgetWrapper from "components/WidgetWrapper";
import { pdfjs, Document, Page } from 'react-pdf';
import pdfWMCFile from './WMC.pdf';
import pdfJETFile from './JET.pdf';
import pdfMarcoPoloFile from './MarcoPolo-1.pdf';
import './declassified.css';


const Declassified = () => {
    const [pdfFiles, setPdfFiles] = useState([pdfWMCFile, pdfJETFile, pdfMarcoPoloFile]);
    const [currentPdfIndex, setCurrentPdfIndex] = useState(0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);

    const documentTitles = ["Milton William Cooper", "Jeffrey Epsteins Flight Logs", "Hunter Bidens Laptop"];



    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
    }, []);


    const handleLoadFullPDF = () => {
        if (!isFullScreen) {
            window.open(pdfFiles[currentPdfIndex], '_blank');
        }
        setIsFullScreen(!isFullScreen);
    };

    const handleNextPDF = () => {
        const nextIndex = (currentPdfIndex + 1) % pdfFiles.length;
        setCurrentPdfIndex(nextIndex);
    };

    const isSmallerScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));


    return (
        <WidgetWrapper marginLeft="-2.3rem">
            <Box
                p="1rem 0"
                className="declassifiedWidgetWrapper" // Add any specific styles here
                style={{ maxWidth: '230px', margin: '0 auto' }}
            >
                 <Typography fontSize="2rem" color="#8a0303" fontWeight="500" mb="1rem" textAlign="center"
                 style={{
                    backgroundColor: 'skyblue',
                    borderRadius: '50px',  // Adjust the border radius as needed
                    padding: '8px',  // Optional: Add padding to give it some space
                }}
                 >
                    Declassified
                </Typography>
                <Typography fontSize="1rem" color="#8a0303" fontWeight="500" mb="1rem" textAlign="center">
                    {documentTitles[currentPdfIndex]}
                </Typography>
                {/* Display PDF thumbnails with links */}
                <div className="pdf-container" style={{ maxHeight: '316px', overflow: 'hidden', position: 'relative' }}>
                    <Document file={pdfFiles[currentPdfIndex]} onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} width={480} />
                    </Document>
                    <div className="button-container" style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '8px' }}>
                        <Button 
                        
                        variant="outlined" onClick={handleLoadFullPDF}>
                            {isFullScreen ? 'Exit Full PDF' : 'Load Full PDF'}
                        </Button>
                        <Button variant="outlined" onClick={handleLoadFullPDF} style={{ marginLeft: '8px' }}>
                            View Document
                        </Button>
                    </div>
                    <Stack direction="row" spacing={2}>
                    <Tooltip
                    title="Next Document"
                    placement="bottom"
                    arrow
                    PopperProps={{ style: { margin: '16px 0' } }}
                >
                    <div className="next-button-container" style={{ position: 'absolute', top: '50%', right: isSmallerScreen ? '10px' : '20px', transform: 'translateY(-50%)' }}>
                        <Button
                            variant="outlined"
                            onClick={handleNextPDF}
                            style={{ zIndex: 1 }}
                        >
                            ▶
                        </Button>
                        
                    </div>
                </Tooltip>
                </Stack>
                </div>
                {/* Add your .pdf file content here */}
            </Box>
        </WidgetWrapper>
    );
};

export default Declassified;
