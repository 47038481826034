import {
  EditOutlined,
  DeleteOutlined,
  AttachFileOutlined,
  GifBoxOutlined,
  ImageOutlined,
  MicOutlined,
  MoreHorizOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Typography,
  InputBase,
  useTheme,
  Button,
  IconButton,
  useMediaQuery,
  Tooltip,
  InputAdornment,
  Stack,
  Dialog,
  DialogContent,
  Paper,
} from "@mui/material";

import FlexBetween from "components/FlexBetween";
import Dropzone from "react-dropzone";
import UserImage from "components/UserImage";
// import img from "../../../src/scenes/widgets/dogecoin.png";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import Linkify from 'react-linkify';
import { useDispatch, useSelector } from "react-redux";
import { setPosts, showSnackbar } from "state";
import { MaskHappy } from "phosphor-react";
import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';
import EmojiPicker from "@emoji-mart/react";
import "./styles.css";
import "./chirp.mp3";
import dogeModelImage from "./dogemodel.gif";
new Picker({ data })


new Picker({
  data: async () => {
    const response = await fetch(
      'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
    )

    return response.json()
  }
})

const MyPostWidget = ({ picturePath, videoPath }) => {
  const [remainingSeconds, setRemainingSeconds] = useState(null);
  const [isCounting, setIsCounting] = useState(false);
  const [countdownInterval, setCountdownInterval] = useState(null);
  const [audioPlayedAtStart, setAudioPlayedAtStart] = useState(false);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);
  const [isAnimationRestarted, setIsAnimationRestarted] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [audioRecorder, setAudioRecorder] = useState(null);
  const [initializeMicrophone, setInitializeMicrophone] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null); // State to store the recorded audio URL
  const [inputValue, setInputValue] = useState("");
  const [audioElement, setAudioElement] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };





  useEffect(() => {
    const initializeMicrophoneHandler = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const recorder = new MediaRecorder(stream);
          setMediaRecorder(recorder);

          recorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              setAudioChunks((chunks) => [...chunks, event.data]);
              // Log to the console when audio data is available
              // console.log('Audio data available:', event.data);
            }
          };

          recorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);

            // Insert audioUrl into your input box or handle it accordingly
            // Store the recorded audio URL in state
            setRecordedAudio(audioUrl);
          };
        } catch (error) {
          console.error('Error accessing microphone:', error);
        }
      }
    };

    if (initializeMicrophone) {
      initializeMicrophoneHandler();
    }
  }, [audioChunks, initializeMicrophone]);


  const startRecording = () => {
    if (countdownInterval) {
      setIsCounting((prevIsCounting) => {
        if (prevIsCounting) {
          clearInterval(countdownInterval);
          setRemainingSeconds(null);
          setIsAnimationPaused(true);
          stopRecording();
          return false;
        } else {
          const newInterval = setInterval(() => {
            setRemainingSeconds((prevSeconds) => {
              if (prevSeconds > 0) {
                return prevSeconds - 1;
              } else {
                clearInterval(newInterval);
                setIsCounting(false);
                setIsAnimationPaused(true);
                stopRecording();
                return null;
              }
            });
          }, 1000);
          setCountdownInterval(newInterval);
          setIsAnimationPaused(false);
        }
        // Check if audioRecorder exists and is not recording
        if (!audioRecorder || audioRecorder.state === "inactive") {
          setInitializeMicrophone(true);
        }

        return !prevIsCounting;
      });
    } else {
      if (!audioPlayedAtStart) {
        playAudio();
        setAudioPlayedAtStart(true);
      }

      const chirpContainer = document.getElementById('chirpContainer');
      chirpContainer.classList.add('active');
      setRemainingSeconds(remainingSeconds !== null ? remainingSeconds : 7);
      setIsCounting(true);

      const newInterval = setInterval(() => {
        setRemainingSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            clearInterval(newInterval);
            setIsCounting(false);
            setIsAnimationRestarted((prev) => !prev);
            stopRecording();
            return null;
          }
        });
      }, 1000);
      setCountdownInterval(newInterval);

      if (!audioRecorder) {
        setInitializeMicrophone(true);
        startAudioRecording();
      }
    }
  };

  const playAudio = () => {
    const audio = new Audio(require('./chirp.mp3'));
    audio.play();
  };



  const setAudioInputValueWithControls = (audioUrl) => {
    const audioElement = document.createElement('audio');
    audioElement.controls = true;
    audioElement.src = audioUrl;
  };

  const startAudioRecording = async () => {
    try {
      // console.log("Recording started");
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      setAudioChunks([]); // Reset audioChunks array
      setAudioRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setAudioChunks((chunks) => [...chunks, event.data]);
        }
      };

      recorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setRecordedAudio(audioUrl);

        // Insert audioUrl into your input box or handle it accordingly
        // For example, you can set it to the state and display it in an audio player.
      };

      recorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };




  const stopRecording = () => {
    playAudio();
    setIsRecording(false);
    // console.log("Recording stopped");
    setIsCounting((prevIsCounting) => {
      if (!prevIsCounting) {
        const chirpContainer = document.getElementById('chirpContainer');
        chirpContainer.classList.remove('paused');
        setIsAnimationRestarted((prev) => !prev);
        setTimeout(() => {
          setIsAnimationPaused(false);
        }, 0);

        // Call setRecordedAudio to store the recorded audio URL
        setRecordedAudio(audioChunks.length > 0 ? URL.createObjectURL(new Blob(audioChunks, { type: 'audio/wav' })) : null);

        // Call setAudioInputValue to store the recorded audio URL in your input box
        if (audioChunks.length > 0) {
          const audioUrl = URL.createObjectURL(new Blob(audioChunks, { type: "audio/wav" }));
          setInputValue(audioUrl);
          setAudioInputValue(audioUrl);
        }

        setCountdownInterval(null);
        setRemainingSeconds(null);
      }
      return false;
    });
  };

  const setAudioInputValue = (audioUrl) => {
    // console.log("Setting audio input value:", audioUrl);
    setInputValue(audioUrl);
  };

  const replayAudio = () => {
    if (recordedAudio) {
      const audio = new Audio(recordedAudio);
      audio.play();
    }
  };




  const dispatch = useDispatch();
  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [imageErrorMessage, setImageErrorMessage] = useState("");
  const [videoErrorMessage, setVideoErrorMessage] = useState("");
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [openPicker, setOpenPicker] = useState(false);
  const [post, setPost] = useState("");


  const handleEmojiSelect = (emoji) => {
    setPost((prevPost) => prevPost + emoji.native);
    setOpenPicker(false);
  };


  // const [isVideoUploadVisible, setIsVideoUploadVisible] = useState(false);

  const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB
  const MAX_VIDEO_DURATION = 900; // 15 minutes
  const allowedVideoTypes = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/mov", "video/avi", "video/mpeg", "video/webm"];
  const allowedVideoExtensions = ["mp4", "mov", "avi", "mpeg", "webm"];



  const [isPostButtonEnabled, setPostButtonEnabled] = useState(true);

  // useEffect(() => {
  //   // Update the post button state whenever post, image, or video changes
  //   handlePostButtonEnable();
  // }, [post, image, video]);

  // const handlePostButtonEnable = () => {
  //   // Enable the post button if there is text in the input or if an image or video is selected
  //   setPostButtonEnabled(!!post || !!image || !!video);
  // };


  const { palette } = useTheme();
  const { _id } = useSelector((state) => state.user);
  const token = useSelector((state) => state.token);

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const mediumMain = palette.neutral.mediumMain;
  const medium = palette.neutral.medium;



  const getVideoDuration = (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");

      // Set up a listener to get the duration once metadata is loaded
      video.addEventListener("loadedmetadata", () => {
        resolve(video.duration);
      });

      // Set the video source to the file object
      video.src = URL.createObjectURL(file);

      // Load metadata to trigger the 'loadedmetadata' event
      video.load();
    });
  };



  const handleRejectedFiles = async (rejectedFiles) => {
    rejectedFiles.forEach(async (file) => {
      try {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (allowedVideoTypes.includes(file.type) || allowedVideoExtensions.includes(fileExtension)) {
          // Handle video
          if (file.size > MAX_FILE_SIZE || (await getVideoDuration(file)) > MAX_VIDEO_DURATION) {
            setVideoErrorMessage("Invalid file. Check file size and duration. size limit 100mb, duration limit. 15:00");
            setPostButtonEnabled(false); // Disable the post button
          } else {
            setVideoErrorMessage("");
            setPostButtonEnabled(true); // Enable the post button
          }
        } else {
          setVideoErrorMessage("Invalid file type. Only supported video formats are allowed.");
          setPostButtonEnabled(false); // Disable the post button
        }
      } catch (error) {
        console.error(error);
        setVideoErrorMessage("Error while processing the video file.");
        setPostButtonEnabled(false); // Disable the post button
      }
    });
  };



  const handlePost = async () => {
    const formData = new FormData();
    formData.append("userId", _id);
    formData.append("description", post);

    if (video) {
      formData.append("file", video);
      formData.append("videoPath", video.name);
    }

    if (image) {
      formData.append("file", image);
      formData.append("picturePath", image.name);
    }

    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
      } else {
        const responseData = await response.json();
        // console.log("Response:", responseData);
        setVideo(null);
        setImage(null);
        dispatch(setPosts({ posts: responseData }));
        setPost("");
        dispatch(showSnackbar({ severity: "success", message: "Post Successful" }));
        console.log("Post Was successful.");
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: "Epic Fail. Your Post Was Not Successful" }));
      console.log("Failed Post.");
      console.error("Error during fetch:", error);
    } finally {
      setPostButtonEnabled(false);
    }

  };






  const handleDrop = (acceptedFiles, type) => {
    // console.log("Drop event:", type, "isImage:", isImage, "isVideo:", isVideo);
    const file = acceptedFiles[0];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/webp"];
    const allowedVideoTypes = ["video/mp4", "video/quicktime", "video/x-msvideo", "video/mov", "video/avi", "video/mpeg", "video/webm"];

    // Always reset both to false when handling drop
    setIsImage(false);
    setIsVideo(false);
    if (type === 'image' && allowedImageTypes.includes(file.type)) {
      // Handle image
      setImage(file);
      setImageErrorMessage("");
      setIsImage(true);  // Set the corresponding type to true
      setVideo(null);  // Clear the video if it exists
    } else if (type === 'video' && allowedVideoTypes.includes(file.type)) {
      // Handle video
      setVideo(file);
      handleRejectedFiles([file]); // Validate the video file
      setIsVideo(true);  // Set the corresponding type to true
      // Clear the image if it exists
      setImage(null);
    } else {
      setImage(null);
      setVideo(null);
      setImageErrorMessage("Invalid file type. Only JPEG, PNG, GIF images, and supported video formats are allowed.");
      setVideoErrorMessage("Invalid file type. Only supported video formats are allowed.");
      setPostButtonEnabled(false);
      return; // Return early to prevent further execution
    }

    // Enable the post button when a valid file is dropped
    setPostButtonEnabled(true);
  };





  return (
    <WidgetWrapper
      width="100%"
    >

      <FlexBetween
        gap="1.5rem" flexDirection="column-reverse">
        <Linkify>


          <InputBase

            placeholder="Don't just talk bout it. 
Be bout it"
            onChange={(e) => setPost(e.target.value)}
            value={post}
            multiline  // Enable multiline input
            rows={4}    // Set the number of visible rows (adjust as needed)
            inputProps={{ maxLength: 316 }}  // Set character limit to 316
            sx={{
              position: "relative",
              width: "100%",
              backgroundColor: palette.neutral.light,
              borderRadius: "2rem",
              padding: "1rem 2rem",
              whiteSpace: "pre-wrap",  // Allow text wrapping
              wordWrap: "break-word",  // Break words when necessary
              minHeight: "6rem", // Adjust the height as needed
            }}
            endAdornment={ // Use InputAdornment for placing the icon

              <InputAdornment position="end">
                {audioElement ? (
                  <Box mt="1rem">
                    <audio controls>
                      <source src={recordedAudio} type="audio/wav" />
                      Your browser does not support the audio element.
                    </audio>
                  </Box>
                ) : (
                  <IconButton
                    onClick={() => {
                      setOpenPicker((prev) => !prev);
                    }}
                    sx={{
                      backgroundColor: '#8a0303',
                      borderRadius: "50%",
                      color: palette.common.white,
                    }}
                  >
                    <MaskHappy />
                  </IconButton>
                )}
              </InputAdornment>
            }

          />



        </Linkify>
        <Box sx=
          {{
            display: openPicker ? "inline" : "none", zIndex: 10, position: "fixed", top: 80, right: 20

          }}>
          <EmojiPicker
            setOpenPicker={() => { }}
            data={data}
            onEmojiSelect={handleEmojiSelect}
          />

        </Box>



        {/* Character limit message */}
        <Typography variant="caption" color="text.secondary" sx={{ textAlign: "right" }}>
          {post.length}/316 <span className="kharacters">kharacters</span>
        </Typography>
        <UserImage image={picturePath} />

      </FlexBetween>
      {isImage && (
        <Box
          border={`1px solid ${medium}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem"
        >
          <Dropzone
            acceptedFiles=".jpg,.jpeg,.png, .gif"
            multiple={false}
            onDrop={(acceptedFiles) => handleDrop(acceptedFiles, 'image')}
          >
            {({ getRootProps, getInputProps }) => (
              <FlexBetween>
                <Box
                  {...getRootProps()}
                  border={`2px dashed ${palette.primary.main}`}
                  p="1rem"
                  width="100%"
                  sx={{ "&:hover": { color: "#8a0303", cursor: "pointer" } }}
                >
                  <input {...getInputProps()} />
                  {!image ? (
                    <p className="upload-img-text">Upload IMAGES or GIFS here. </p>
                  ) : (
                    <FlexBetween>
                      <Typography>{image.name}</Typography>
                      <EditOutlined />
                    </FlexBetween>
                  )}
                </Box>
                {image && (
                  <IconButton
                    onClick={() => setImage(null)}
                    sx={{ width: "15%" }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </FlexBetween>
            )}
          </Dropzone>
        </Box>
      )}

      {isVideo && (
        <Box
          border={`1px solid ${medium}`}
          borderRadius="5px"
          mt="1rem"
          p="1rem">
          <Dropzone
            acceptedFiles=".mp4, .mov, .avi, .mpeg, .webm"
            multiple={false}
            onDrop={(acceptedFiles) => handleDrop(acceptedFiles, 'video')}
          >
            {({ getRootProps, getInputProps }) => (
              <FlexBetween>
                <Box
                  {...getRootProps()}
                  border={`2px dashed ${palette.primary.main}`}
                  p="1rem"
                  width="100%"
                  sx={{ "&:hover": { color: "#8a0303", cursor: "pointer" } }}
                >
                  <input {...getInputProps()} />
                  {!video ? (
                    <p className="upload-vid-text">Upload Videos not exceeding 15:00 Duration && 100mb size.</p>
                  ) : (
                    <FlexBetween>
                      <Typography>{video.name}</Typography>
                      <EditOutlined />
                    </FlexBetween>
                  )}
                </Box>
                {video && (
                  <IconButton
                    onClick={() => setVideo(null)}
                    sx={{ width: "15%" }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </FlexBetween>
            )}
          </Dropzone>
        </Box>
      )}


      {videoErrorMessage && (
        <Typography variant="caption" color="error" sx={{ marginTop: "0.5rem", marginLeft: "8rem" }}>
          {videoErrorMessage}
        </Typography>
      )}


      <Divider sx={{ margin: "1.25rem 0" }} />

      <FlexBetween gap="0.25rem" onClick={(event) => {
        event.stopPropagation();
        // console.log("Clicked on Image icon");
        setIsImage(!isImage);
        setIsVideo(false);
      }}>

        <Tooltip title="Upload Image">
          <ImageOutlined sx={{ color: "green" }} />
          <Typography
            color={mediumMain}
            sx={{ "&:hover": { cursor: "pointer", color: "red" } }}
          >
            Image
          </Typography>
        </Tooltip>
        {!isNonMobileScreens && (
          <FlexBetween gap="0.25rem" onClick={(event) => {
            event.stopPropagation();
            event.preventDefault(); // Prevent the default behavior on mobile devices
            // console.log("Clicked on Video icon");
            setIsVideo(!isVideo);
            setIsImage(false);
          }}>
            <Tooltip title="Video Upload">
              <VideocamOutlined sx={{ color: "red", marginLeft: "5px" }} />
              <Typography color={mediumMain} sx={{ marginLeft: "5px", "&:hover": { cursor: "pointer", color: "green" } }}>Video</Typography>
            </Tooltip>
          </FlexBetween>
        )}
        <FlexBetween gap="0.25rem" style={{ alignItems: "center", flexDirection: "column" }}>
          <Tooltip title="What's next? Can't Tell">
            <div
              id="chirpContainer"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the event from propagating to the parent (upload dropzone)
                playAudio();

                if (isCounting) {
                  clearInterval(countdownInterval);
                  setIsCounting(false);
                  document.getElementById('chirpContainer').classList.add('paused');
                } else {
                  startRecording();
                  document.getElementById('chirpContainer').classList.remove('paused');
                }

                document.getElementById('chirpContainer').key = Date.now();
              }}
            >
              <img id="chirpImg" src="../assets/walkie-talkie.png" alt="chirp" width="30px" style={{ marginLeft: "1px" }} />
              <div id="countdownRing" className={`ring ${isAnimationPaused ? 'paused' : ''}`}></div>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                // Rest of the Chirp click handling code...
              }}
            >
              <Typography color={mediumMain} sx={{ "&:hover": { cursor: "pointer", color: "skyblue" } }}>Chirp</Typography>
            </div>
          </Tooltip>
        </FlexBetween>





        {isNonMobileScreens ? (
          <>
            <FlexBetween gap="0.25rem" onClick={(event) => {
              event.stopPropagation();
              setIsImage(!isImage);
              setIsVideo(!isVideo); // Toggle the isVideo state
            }}>
              <Tooltip title="Video Upload">
                <VideocamOutlined sx={{ color: isVideo ? "green" : "red" }} />
                <Typography color={mediumMain} sx={{ "&:hover": { cursor: "pointer", color: isVideo ? "green" : "red" } }}>Video</Typography>
              </Tooltip>
            </FlexBetween>


            <FlexBetween gap="0.25rem">
              <Tooltip title="Send Doge">
                <img src="../assets/dogecoin.png" alt="twitter" width="50px" style={{ marginLeft: "12px", marginTop: "-18px" }} />
                {/* <AttachFileOutlined sx={{ color: mediumMain }} /> */}
                <Typography color={mediumMain}>Dog£ Pound </Typography>
              </Tooltip>
            </FlexBetween>




          </>
        ) : (
          <FlexBetween gap="0.25rem">
            <MoreHorizOutlined
              sx={{ color: mediumMain, cursor: 'pointer' }}
              onClick={handleDialogOpen}
            />
          </FlexBetween>
        )}
        <Dialog
          open={isDialogOpen}
          onClose={handleDialogClose}
          PaperComponent={({ children }) => (
            <Paper
              sx={{
                backgroundColor: 'transparent',
                display: 'flex',
                justifyContent: 'flex-end', // Align to the right
                alignItems: 'flex-start',   // Align to the top
                margin: '0 16px',           // Adjust the margin as needed
                width: 'auto',              // Allow content to determine the width
              }}
            >
              {children}
            </Paper>
          )}
        >
          <DialogContent>
            {/* Content of the dialog */}
            <Typography sx={{ textAlign: 'right' }}>🚀🌕</Typography>
            <img
              src={dogeModelImage}
              alt="Dialog Background"
              style={{
                width: '69px',
                height: '69px',
                objectFit: 'cover',
                borderRadius: '50%',
                marginTop: "-40px",
              }}
            />
            <Typography style={{ color: '#8a0303', fontSize: "19px", letterSpacing: '2px' }}>MUCH WOW</Typography>
          </DialogContent>
        </Dialog>
        {/* Countdown Typography and Button container */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '-19px' }}>
          {remainingSeconds !== null && (
            <Typography color="red">{remainingSeconds} seconds</Typography>
          )}


        </div>


      </FlexBetween>
      <div style={{ display: 'flex', marginLeft: 'auto' }}>
        <Button
          disabled={!post || !isPostButtonEnabled}
          onClick={(e) => {
            e.stopPropagation(); // Prevent the event from propagating to the parent elements
            handlePost();
          }}
          sx={{
            color: "skyblue",
            backgroundColor: "#8a0303",
            fontFamily: "mortal kombat",
            borderRadius: "3rem",
            marginLeft: 'auto',
            "&:hover": { backgroundColor: "skyblue", color: "#8a0303" },
          }}
        >
          Chirp
        </Button>
      </div>
    </WidgetWrapper>
  );
};

export default MyPostWidget;