import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";



// Thunk to handle incrementing profile views
export const incrementViewedProfile = createAsyncThunk(
  "user/incrementViewedProfile",
  async (userId, { getState, rejectWithValue }) => {
    const { token } = getState().auth;
    // console.log(`Incrementing profile view for user: ${userId}`);
    try {
      const response = await axios.patch(
        `https://chirpskykite-server.onrender.com/profile/view/${userId}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // console.log(`API response: ${JSON.stringify(response.data)}`);
      return response.data;
    } catch (error) {
      console.error(`API error: ${error.message}`);
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  mode: "light",
  user: null,
  friends: [],
    isFriend: false,
  token: null,
  posts: [],
  snackbar: {
    open: null,
    message: null,
    severity: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {




    // incrementViewedProfile: (state) => {
    //   if (state.user) {
    //     return {
    //       ...state,
    //       user: {
    //         ...state.user,
    //         viewedProfile: state.user.viewedProfile + 1,
    //       },
    //     };
    //   }
    //   return state;
    // },
    // incrementImpressions: (state) => {
    //   if (state.user) {
    //     return {
    //       ...state,
    //       user: {
    //         ...state.user,
    //         impressions: state.user.impressions + 1,
    //       },
    //     };
    //   }
    //   return state;
    // },
    openSnackbar(state, action) {
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },
    closeSnackbar(state, action) {
      state.snackbar.open = false;
      state.snackbar.severity = null;
      state.snackbar.message = null;


    },



    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null;
      state.token = null;

    },
    // setFriends: (state, action) => {
    //   if (state.user) {
    //     state.user.friends = action.payload.friends;
    //   } else if (action.payload && action.payload.error) {
    //     console.error("user friends non-existent :(");
    //     console.error("Error updating user friends:", action.payload.error);
    //   }
    // },

    setFriends: (state, action) => {
      state.user.friends = action.payload.friends;
      state.isFriend = action.payload.isFriend;
    },


    deletePost: (state, action) => {
      const postIdToDelete = action.payload; // Pass the post ID to delete
      state.posts = state.posts.filter((post) => post._id !== postIdToDelete);
    },

    deleteComment: (state, action) => {
      const { postId, commentId } = action.payload;
      const post = state.posts.find(post => post._id === postId);
      if (post) {
        post.comments = post.comments.filter(comment => comment._id !== commentId);
      }
    },

    handleEditComment: (state, action) => {
      const { postId, commentId, updatedComment } = action.payload;
      const post = state.posts.find(post => post._id === postId);
      if (post) {
        const comment = post.comments.find(comment => comment._id === commentId);
        if (comment) {
          comment.commentText = updatedComment.commentText;
          comment.updatedAt = updatedComment.updatedAt; 
        }
      }
    },
handleEditPost: (state, action) => {
  const { postId, updatedPost } = action.payload;
  return {
    ...state,
    posts: state.posts.map(post => {
      if (post._id === postId) {
        return {
          ...post,
          description: updatedPost.description,
          picturePath: updatedPost.picturePath,
          videoPath: updatedPost.videoPath,
          updatedAt: updatedPost.updatedAt,
        };
      }
      return post;
    }),
  };
},




    setPosts: (state, action) => {
      // console.log("Cloudinary Response:", action.payload.posts);
      state.posts = action.payload.posts;
    },

    setPost: (state, action) => {
      const updatedPosts = state.posts.map((post) => {
        if (post._id === action.payload.post._id) return action.payload.post;
        return post;
      });

      state.posts = updatedPosts;
    },
    addPost: (state, action) => {
      state.posts.push(action.payload.post);
    },
    setComments: (state, action) => {
      state.comments = action.payload.comments;
    },
    setComment: (state, action) => {
      const updatedComments = state.comments.map((comment) => {
        if (comment._id === action.payload.comment._id) return action.payload.comment;
        return comment;
      });

      state.comments = updatedComments;
    },

  },


  setCommentReplies: (state, action) => {
    // Assuming action.payload.commentId is the ID of the parent comment
    const { commentId, replies } = action.payload;

    const updatedComments = state.comments.map((comment) => {
      if (comment._id === commentId) {
        return { ...comment, replies };
      }
      return comment;
    });

    state.comments = updatedComments;
  },

  extraReducers: (builder) => {
    builder.addCase(incrementViewedProfile.fulfilled, (state, action) => {
      if (state.user) {
        state.user.viewedProfile += 1;
      }
    });
  },
});




export function showSnackbar({ severity, message }) {
  return async (dispatch, getState) => {
    dispatch(
      authSlice.actions.openSnackbar({
        message, severity
      })
    );
    setTimeout(() => {
      dispatch(
        authSlice.actions.closeSnackbar());
    }, 4000);


  };
}

export const closeSnackbar = () => async (dispatch, getState) => {
  dispatch(authSlice.actions.closeSnackbar());
};






export const { setMode, setLogin, setLogout, setFriends, setPosts, setPost, addPost, deletePost, setComments, setComment, deleteComment, handleEditComment , handleEditPost, setCommentReplies, setCommentReply } =
  authSlice.actions;
export default authSlice.reducer;