import React from 'react'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPosts } from "state";
import PostWidget from "./PostWidget";
import { format } from "date-fns";
import Linkify from 'react-linkify';
import ReactLinkify from 'react-linkify';
import LinkPreview from '@ashwamegh/react-link-preview';
import '@ashwamegh/react-link-preview/dist/index.css';
import '../../index.css';

// ADD ROUTE FOR VIDEO ALSO
const PostsWidget = ({ userId, isProfile = false }) => {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.posts);
  const token = useSelector((state) => state.token);

  const extractUrlFromDescription = (description) => {
    // Use a regular expression to find URLs in the description
    const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;
    const matches = description.match(urlRegex);

    // If matches are found, return the first URL, otherwise return the original description
    return matches ? (matches[0].startsWith('http') ? matches[0] : `https://${matches[0]}`) : description;
  };





  const getPosts = async () => {
    const response = await fetch("https://chirpskykite-server.onrender.com/posts", {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    const postsWithTimestamps = data.map((post) => {
      const formattedTimestamp = post.createdAt ? format(new Date(post.createdAt), "MMM dd, yyyy HH:mm") : "";
      return { ...post, formattedTimestamp };
    });

    const sortedPosts = postsWithTimestamps.sort((a, b) =>
      new Date(b.createdAt) - new Date(a.createdAt)
    );

    dispatch(setPosts({ posts: postsWithTimestamps }));

  };


  // ADD ROUTE FOR VIDEO ALSO
  const getUserPosts = async () => {
    const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${userId}/posts`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    const postsWithTimestamps = data.map((post) => {
      const formattedTimestamp = post.createdAt ? format(new Date(post.createdAt), "MMM dd, yyyy HH:mm") : "";
      return { ...post, formattedTimestamp };
    });



    const sortedPosts = postsWithTimestamps.sort((a, b) =>
      new Date(b.createdAt) - new Date(a.createdAt)
    );
    dispatch(setPosts({ posts: postsWithTimestamps }));

  };

  useEffect(() => {
    if (isProfile) {
      getUserPosts();
    } else {
      getPosts();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // console.log(posts);
  //console.log(formattedTimestamp)

  return (
    <>
      {posts && posts.length > 0 ? (
        posts.map(
          ({
            _id,
            userId,
            firstName,
            lastName,
            username,
            description,
            location,
            picturePath,
            videoPath,
            userPicturePath,
            likes,
            hates,
            boomerangs,
            comments,
            formattedTimestamp,
            
          }) => (
            <PostWidget
              key={_id}
              postId={_id}
              postUserId={userId}
              name={`${firstName} ${lastName}`}
              username={username}
              description={description && (
                <span>
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="_blank" rel="noopener noreferrer" href={decoratedHref} key={key} className="noUnderline">
                        {decoratedText}
                      </a>
                    )}
                  >
                    {description}
                  </Linkify>
                  <LinkPreview
                    url={extractUrlFromDescription(description)}
                    className="custom-link-preview"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior
                      const url = extractUrlFromDescription(description);
                      if (url) {
                        // Prepend "https://" if not present
                        const urlWithProtocol = url.startsWith('http') ? url : `https://${url}`;
                        window.open(urlWithProtocol, '_blank');
                      }
                    }}
                  />

                </span>
              )}
              location={location}
              picturePath={picturePath}
              videoPath={videoPath}
              userPicturePath={userPicturePath}
              likes={likes}
              hates={hates}
              boomerangs={boomerangs ?? {}}
              comments={comments}
              formattedTimestamp={formattedTimestamp}
            />
          )
        )
      ) : (
        <p>No Chirps available.</p>
      )}
    </>
  );
};

export default PostsWidget;