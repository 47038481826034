import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined, Input, Check as CheckIcon
} from "@mui/icons-material";
import {
  Box, Typography, Divider, useTheme, IconButton, TextField, Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  InputAdornment,
  MenuItem
} from "@mui/material";
import UserImage from "components/UserImage";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { incrementViewedProfile, showSnackbar, setLogout } from "state";
import '../../index.css'
import { id } from "date-fns/locale";
import { Eye, SmileyXEyes } from "phosphor-react";

const UserWidget = ({ userId, picturePath  }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      // console.log(`UserWidget mounted for user`);
      dispatch(incrementViewedProfile(userId));
    }
  }, [dispatch, userId]);

  // const socialProfiles 
  const socialProfiles = [
    { platform: "𐕣🔱i❌ter", description: "Pillage Village Xenoprivilege", icon: "../assets/twitter.png" },
    { platform: "LinkedIn", description: "Strictly Business", icon: "../assets/linkedin.png" },
    { platform: "Personal", description: <>Q: Who am I?<br />¡ʎʞs ǝɥʇ oʇ ʞoo˥ :∀</>, icon: "../assets/tbird.png" },
    // { platform: "About Me", description: "Tell us about yourself", icon: "../assets/about.png" },  

  ];


  const [user, setUser] = useState(null);
  const { palette } = useTheme();
  const navigate = useNavigate();
  const token = useSelector((state) => state.token);
  const [editMode, setEditMode] = useState(Array(socialProfiles.length).fill(false));
  const [editValue, setEditValue] = useState(Array(socialProfiles.length).fill(''))
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const currentUserId = useSelector((state) => state.user._id);

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    location: "",
    occupation: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState(false);

  const getCurrentUserInfo = async () => {
    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/users/${userId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setUserInfo(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    getCurrentUserInfo();
  }, []); // Fetch user data on component mount




  const handleSubmit = async () => {
    if (userInfo.password !== userInfo.confirmPassword) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);

    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/users/${userId}/update-profile`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(userInfo),
      });
      const data = await response.json();
      console.log("Updated user info:");
      handleCloseModal();
      dispatch(showSnackbar({ severity: "success", message: "Profile Updated Successfully!" }));

    } catch (error) {
      console.error("Error updating user data:", error);
      dispatch(showSnackbar({ severity: "error", message: "Registration failed. Please try again." }));

    }
  };


  const theme = useTheme();
  // const neutralLight = theme.palette.neutral.light;
  // const background = theme.palette.background.default;
  // const primaryLight = theme.palette.primary.light;
  // const alt = theme.palette.background.alt;


  // State to manage edit mode for each social profile
  // const [twitterEditMode, setTwitterEditMode] = useState(false);
  // const [linkedinEditMode, setLinkedinEditMode] = useState(false);
  // State to manage the input value in edit mode


  // Function to toggle edit mode for each social profile
  const handleToggleEditMode = (index) => {
    const newEditMode = [...editMode];
    newEditMode[index] = !newEditMode[index];
    setEditMode(newEditMode);
  };



// FUNCTION TO HANDLE CHANGES WHEN EDITING SOCIAL PROFILE
const handleSocialProfileChange = (index, newValue) => {
  const newEditValue = [...editValue];
  newEditValue[index] = newValue;
  setEditValue(newEditValue);
};


  // Function to handle changes when editing social profile
  const handleSaveProfileChanges = async (index) => {
    try {
      const updatedSocialProfiles = [...(user.socialProfiles || [])];
      updatedSocialProfiles[index] = { platform: socialProfiles[index].platform, url: editValue[index] || '' };

      const response = await fetch(`https://chirpskykite-server.onrender.com/users/${userId}/social-profiles`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ socialProfiles: updatedSocialProfiles }),
      });

      if (!response.ok) {
        throw new Error(`Failed to update social profiles (${response.status})`);
      }

      setUser((prevUser) => ({ ...prevUser, socialProfiles: updatedSocialProfiles }));
      handleToggleEditMode(index);
      dispatch(showSnackbar({ severity: 'success', message: 'Social profile updated successfully!' }));
    } catch (error) {
      console.error('Error updating social profiles:', error);
      dispatch(showSnackbar({ severity: 'error', message: 'Failed to update social profile. Please try again.' }));
    }
  };



  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);


  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  const getUser = async () => {
    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/users/${userId}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });

      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle the error appropriately, such as displaying an error message to the user.
    }
  };

  useEffect(() => {
    getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!user) {
    return null;
  }

  const {
    firstName,
    lastName,
    username,
    location,
    occupation,
    viewedProfile,
    impressions,
    friends,
  } = user;

  return (
    <WidgetWrapper marginLeft="-2.3rem">
      {/* FIRST ROW */}
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1rem">
          <UserImage image={picturePath} />
          <Box>
            <Typography
              variant="h4"
              color="#8a0303"
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: "skyblue",
                  cursor: "pointer",
                },
              }}
              onClick={() => navigate(`/profile/${userId}`)}

            >
              {firstName} {lastName}
            </Typography>
            <Typography
              color="skyblue" fontSize="1.5rem">{`@${username}`}</Typography>
            <Typography color={medium}>{friends.length} Friendships 🗽 Pretendships</Typography>
          </Box>
        </FlexBetween>
        {currentUserId === userId && (
          <ManageAccountsOutlined onClick={handleOpenModal} className="managed-pointer" />
        )}
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p="1rem 0">
        <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
          <LocationOnOutlined fontSize="large" sx={{ color: "skyblue" }} />
          <Typography color={medium}>{location}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          <WorkOutlineOutlined fontSize="large" sx={{ color: "green" }} />
          <Typography color={medium}>{occupation}</Typography>
        </Box>
      </Box>

      <Divider />

      {/* THIRD ROW */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography color={medium}># of Profile Views</Typography>
          <Typography color={main} fontWeight="500">
            {viewedProfile}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography color={medium}>Impressions of your Chirps</Typography>
          <Typography color={main} fontWeight="500">
            {impressions}
          </Typography>
        </FlexBetween>
      </Box>

      <Divider />

      {/* FOURTH ROW */}
      <Box p="1rem 0">
        <Typography fontSize="1rem" color="#8a0303" fontWeight="500" mb="1rem" textAlign="center">
          Social Profiles
        </Typography>

        <FlexBetween gap="1rem" mb="0.5rem">
          <FlexBetween gap="1rem">

          </FlexBetween>
        </FlexBetween>
        {socialProfiles.map((profile, index) => (
          <FlexBetween gap="1rem" mb="0.5rem" key={index}>
            <FlexBetween gap="1rem">
              <img src={profile.icon} alt={profile.platform} width="60px" style={{ marginLeft: "-15px" }} />
              <Box textAlign="left">
                <Typography color="#8a0303" fontWeight="500">
                  {profile.platform}
                </Typography>
                {/* Check if 'url' is an object */}
                {editMode[index] ? (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <TextField
                    placeholder={`Enter new ${profile.platform} link`}
                    value={editValue[index]}
                    onChange={(e) => handleSocialProfileChange(index, e.target.value)}
                    inputProps={{ maxLength: 24 }}
                  />
                  <IconButton sx={{ color: '#8a0303' }} onClick={() => handleSaveProfileChanges(index)}>
                    <CheckIcon />
                  </IconButton>
                </div>
              ) : (
                <div>
                  {user.socialProfiles && user.socialProfiles[index] && (
                    <a
                      href={typeof user.socialProfiles[index].url === 'object' ? user.socialProfiles[index].url.url : user.socialProfiles[index].url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="noUnderline"
                    >
                      {typeof user.socialProfiles[index].url === 'object' ? user.socialProfiles[index].url.url : user.socialProfiles[index].url}
                    </a>
                  )}
                  <Typography color="#444">{profile.description}</Typography>
                </div>
              )}
            </Box>
          </FlexBetween>
          {currentUserId === userId && (
            <IconButton sx={{ color: '#8a0303' }} onClick={() => handleToggleEditMode(index)}>
              <EditOutlined />
            </IconButton>
          )}
        </FlexBetween>
      ))}
    </Box>

      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <DialogTitle> <Typography
          variant="h3"
          fontFamily="mortal kombat"
          align="center"
          fontWeight="bold"
          color={theme.palette.mode === "dark" ? "skyblue" : "#8a0303"}
        >
          Edit Profile
        </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" mb={3}>
            <UserImage image={picturePath} style={{ width: 100, height: 100, borderRadius: '50%' }} />
          </Box>
          {/* JSX for input fields with populated placeholders */}
          <Stack spacing={2}>
            <TextField
              label={`First Name`}
              fullWidth
              value={userInfo.firstName}
              onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
            />
            <TextField
              label={`Last Name`}
              fullWidth
              value={userInfo.lastName}
              onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
            />
            <TextField
              label={`Username`}
              fullWidth
              value={userInfo.username}
              onChange={(e) => setUserInfo({ ...userInfo, username: e.target.value })}
            />
            <TextField
              label={`Email`}
              fullWidth
              value={userInfo.email}
              onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
            />
            {/* Remove password field from here */}
            <TextField
              label={`Location`}
              fullWidth
              value={userInfo.location}
              onChange={(e) => setUserInfo({ ...userInfo, location: e.target.value })}
            />
            <TextField
              label={`Occupation`}
              fullWidth
              value={userInfo.occupation}
              onChange={(e) => setUserInfo({ ...userInfo, occupation: e.target.value })}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={userInfo.password}
              onChange={(e) => setUserInfo({ ...userInfo, password: e.target.value })}
              error={passwordError}
              helperText={passwordError ? "Passwords do not match" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <Eye
                        onClick={() => setShowPassword(false)}
                        style={{ cursor: 'pointer', color: "#8a0303", fontSize: '24px' }}
                      />
                    ) : (
                      <SmileyXEyes
                        onClick={() => setShowPassword(true)}
                        style={{ cursor: 'pointer', color: "#1DA1F2", fontSize: '24px' }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={showPassword ? "text" : "password"}

              fullWidth
              value={userInfo.confirmPassword}
              onChange={(e) => setUserInfo({ ...userInfo, confirmPassword: e.target.value })}
              error={passwordError}
              helperText={passwordError ? "Passwords do not match" : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <Eye
                        onClick={() => setShowPassword(false)}
                        style={{ cursor: 'pointer', color: "#8a0303", fontSize: '24px' }}
                      />
                    ) : (
                      <SmileyXEyes
                        onClick={() => setShowPassword(true)}
                        style={{ cursor: 'pointer', color: "#1DA1F2", fontSize: '24px' }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
        <MenuItem sx={{ fontSize: "1.2rem" }} onClick={() => dispatch(setLogout())}>Sign Out</MenuItem>
          <Button onClick={handleCloseModal} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          
        </DialogActions>
      </Dialog>
    </WidgetWrapper>
  );
};

export default UserWidget;