import {
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  ShareOutlined,
  ThumbDown,
  ThumbDownOutlined,
  ThreeSixty,
  ThreeSixtyOutlined,
  DeleteOutline,
  EditOutlined,
} from "@mui/icons-material";
import DoneIcon from '@mui/icons-material/Done';
import { Icon } from '@iconify/react';
import { Box, Divider, IconButton, Dialog, Typography, useTheme, Tooltip, TextField, Button, DialogContent, InputAdornment, Snackbar } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";
import Friend from "components/Friend";
import WidgetWrapper from "components/WidgetWrapper";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deletePost, setPost, addPost, setFriends, deleteComment, handleEditComment, handleEditPost, setCommentReplies, showSnackbar } from "state";

import { format } from "date-fns";
import '../../index.css';
import { MaskHappy } from "phosphor-react";

import data from '@emoji-mart/data';
import { Picker } from 'emoji-mart';
import EmojiPicker from "@emoji-mart/react";
import UserImage from "components/UserImage";


const PostWidget = ({
  postId,
  postUserId,
  name,
  username,
  description,
  location,
  picturePath,
  videoPath,
  userPicturePath,
  likes,
  hates,
  boomerangs,
  comments,
  formattedTimestamp,
}) => {
  const [isComments, setIsComments] = useState(false);
  const [isCommentsReply, setIsCommentsReply] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openPickerEdit, setOpenPickerEdit] = useState(false); // Emoji picker for editing comments
  // const [editedCommentText, setEditedCommentText] = useState(commentText);


  const friends = useSelector((state) => state.user.friends); 
  // const isFriend = friends.some((friend) => friend.friendId === postUserId);

  const [isImageDialogOpen, setIsImageDialogOpen] = useState(false);
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);


  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const [zoomLevel, setZoomLevel] = useState(1);


  new Picker({ data })


  new Picker({
    data: async () => {
      const response = await fetch(
        'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
      )

      return response.json()
    }
  })

  const [openPicker, setOpenPicker] = useState(false);

  const handleEmojiSelect = (emoji) => {
    setCommentContent((prevContent) => prevContent + emoji.native);
    setOpenPicker(false);
  };

  const handleImageDialogOpen = () => {
    setIsImageDialogOpen(true);
  };

  const handleVideoClick = () => {
    setIsVideoDialogOpen(true);
  };

  const handleImageDialogClose = () => {
    setZoomLevel(1);
    setIsImageDialogOpen(false);
  };

  const handleVideoDialogClose = () => {
    setIsVideoDialogOpen(false);
  };


  const handleImageWheel = (e) => {
    setZoomLevel((prevZoom) => prevZoom + (e.deltaY > 0 ? -0.1 : 0.1));
    e.preventDefault();
  };
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const loggedInUserId = useSelector((state) => state.user._id);
  // const isLiked = Boolean(likes[loggedInUserId]);
  // const likeCount = Object.keys(likes).length;
  const [isLikedPost, setIsLikedPost] = useState(Boolean(likes[loggedInUserId]));
  const [likeCountPost, setLikeCountPost] = useState(Object.keys(likes).length);
  // const isLikedComment = Boolean(likes[loggedInUserId]);
  // const likeCountComment = Object.keys(likes).length;
  const [isLikedComment, setIsLikedComment] = useState(Boolean(likes[loggedInUserId]));
  const [likeCountComment, setLikeCountComment] = useState(Object.keys(likes).length);




  const [isHatedComment, setIsHatedComment] = useState(Boolean(likes[loggedInUserId]));
  const [hateCountComment, setHateCountComment] = useState(0);

  const [isBoomerangedComment, setIsBoomerangedComment] = useState(false);
  const [boomerangCountComment, setBoomerangCountComment] = useState(Object.keys(likes).length);






  const isHated = Boolean(hates[loggedInUserId]);
  const hateCount = Object.keys(hates).length;
  const isBoomeranged = Boolean(boomerangs[loggedInUserId]);
  const boomerangCount = Object.keys(boomerangs).length;

  const commentCount = Object.keys(comments).length;

  // Initialize comment states with default values for likes, hates, boomerangs
  // const [commentStates, setCommentStates] = useState(
  //   comments.map((comment) => ({
  //     ...comment,
  //     likes: comment.likes || {},
  //     hates: comment.hates || {},
  //     boomerangs: comment.boomerangs || {},
  //     isLiked: Boolean(comment.likes && comment.likes[loggedInUserId]),
  //     likeCount: Object.keys(comment.likes || {}).length,
  //     isHated: Boolean(comment.hates && comment.hates[loggedInUserId]),
  //     hateCount: Object.keys(comment.hates || {}).length,
  //     isBoomeranged: Boolean(comment.boomerangs && comment.boomerangs[loggedInUserId]),
  //     boomerangCount: Object.keys(comment.boomerangs || {}).length,
  //   }))
  // );

  const [commentContent, setCommentContent] = useState("");
  const commentReplyCount = Object.keys(comments).length;
  const [replyContent, setReplyContent] = useState("");
  const [editedCommentText, setEditedCommentText] = useState("");
  const [editedReplyText, setEditedReplyText] = useState("");


  const [editingComments, setEditingComments] = useState([]);



  // Check if the logged-in user is the owner of the post
  const isOwnPost = postUserId === loggedInUserId;

  const [posts, setPosts] = useState([]);
  // const [commentLikes, setComment] = useState([]);
  const [isEditingPost, setIsEditingPost] = useState(false);

  const [editedPostDescription, setEditedPostDescription] = useState(description);

  const currentUserId = useSelector((state) => state.user._id);



  const [message, setMessage] = useState(null);
  // const [isLikedComment, setIsLikedComment] = useState(Boolean(comments[loggedInUserId]?.likes));
  // const [likeCountComment, setLikeCountComment] = useState(Object.keys(comments[loggedInUserId]?.likes || {}).length);


  // ADD DELETE VIDEO POST
  const handleDeletePost = async () => {
    if (postId === undefined) {
      console.error("postId is undefined. Check how postId is passed to the component.");
      return;
    }

    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        // Delete post from UI or trigger a refresh of the posts
        // e.g., dispatch an action to update the posts state
        dispatch(deletePost(postId));
        setMessage('Post deleted successfully.');
        // console.log('Post deleted successfully.');
        // After a successful delete, you can also remove the deleted post from the state
        setPosts(posts.filter((post) => post._id !== postId));
        fetchPosts(); // Refresh the list of posts after deletion
      } else {
        setMessage('Failed to delete the post.');
        // console.log('Failed to delete the post.');
      }
    } catch (error) {
      setMessage('Error deleting the post: ' + error.message);
      console.error('Error deleting the post:', error);
    }
  };

  // FETCH VIDEO POSTS ALSO
  const fetchPosts = async () => {
    try {
      const response = await fetch("https://chirpskykite-server.onrender.com/posts/");
      if (response.ok) {
        const updatedPosts = await response.json();
        setPosts(updatedPosts);
      } else {
        // console.log("Failed to fetch posts.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };


  //   const fetchVideoPosts = async () => {
  //   try {
  //     const response = await fetch("http://localhost:3001/posts/video");
  //     if (response.ok) {
  //       const updatedPosts = await response.json();
  //       setPosts(updatedPosts);
  //     } else {
  //       console.log("Failed to fetch posts.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching posts:", error);
  //   }
  // };




  const { palette } = useTheme();
  const main = palette.neutral.main;
  const primary = palette.primary.main;

  // POSTS LIKES
  // const patchLike = async () => {
  //   const response = await fetch(`http://localhost:3001/posts/${postId}/like`, {
  //     method: "PATCH",
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({ userId: loggedInUserId }),
  //   });
  //   const updatedPost = await response.json();
  //   dispatch(setPost({ post: updatedPost }));
  // };




  // const patchLikeComment = async (commentId) => {
  //   try {
  //     const response = await fetch(`http://localhost:3001/posts/${postId}/like-comment`, {
  //       method: "PATCH",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({ userId: loggedInUserId, commentId }),
  //     });

  //     if (response.ok) {
  //       const updatedPost = await response.json();
  //       dispatch(setPost({ post: updatedPost }));
  //     } else {
  //       // Handle error, log or show user feedback
  //       console.error("Failed to like the comment.");
  //     }
  //   } catch (error) {
  //     // Handle error, log or show user feedback
  //     console.error("Error liking the comment:", error);
  //   }
  // };



  // Update patchLike to set likes for posts
  const patchLike = async () => {
    const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/like`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    setIsLikedPost(!isLikedPost); // Toggle the like status
    setLikeCountPost(Object.keys(updatedPost.likes).length); // Update like count
    dispatch(setPost({ post: updatedPost }));

  };

  // Update patchLikeComment to set likes for comments
  const patchLikeComment = async (commentId) => {
    try {
      const response = await fetch(
        `https://chirpskykite-server.onrender.com/posts/${postId}/comment/${commentId}/like-comment`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to like the comment");
      }

      const updatedPost = await response.json();

      // Find the updated comment
      const updatedComment = updatedPost.comments.find(comment => comment._id === commentId);

      // Update the state with the new like status and count
      setIsLikedComment(!isLikedComment);
      setLikeCountComment(Object.keys(updatedComment.likes).length); // Update like count

      // Dispatch the updated post after liking the comment
      dispatch(setPost({ post: updatedPost }));

    } catch (error) {
      console.error("Error liking the comment:", error.message);
    }
  };


  const patchHate = async () => {
    const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/hate`, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ userId: loggedInUserId }),
    });
    const updatedPost = await response.json();
    dispatch(setPost({ post: updatedPost }));
  };


  const patchHateComment = async (commentId) => {
    try {
      const response = await fetch(
        `https://chirpskykite-server.onrender.com/posts/${postId}/comment/${commentId}/hate-comment`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to Hate the comment");
      }

      const updatedPost = await response.json();

      // Find the updated comment
      const updatedComment = updatedPost.comments.find(comment => comment._id === commentId);

      // Update the state with the new like status and count
      setIsHatedComment(!isHatedComment);
      setHateCountComment(Object.keys(updatedComment.hates).length); // Update like count

      // Dispatch the updated post after liking the comment
      dispatch(setPost({ post: updatedPost }));

    } catch (error) {
      console.error("Error Hating the comment:", error.message);
    }
  };




  const patchBoomerang = async () => {
    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/boomerang`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ userId: loggedInUserId }),
      });

      if (response.ok) {
        const updatedPost = await response.json();
        dispatch(setPost({ post: updatedPost }));

        // Get the current timestamp
        const timestamp = new Date().toISOString();

        // Post the boomeranged post on the user's timeline (usersPostFeed)
        await fetch("https://chirpskykite-server.onrender.com/posts", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            userId: loggedInUserId,
            description: `${description}`, // Add a "Boomeranged" caption
            timestamp: timestamp, // Include the timestamp
            picturePath,
            videoPath
          }),
        });

        // Fetch and set the updated posts after boomeranging
        fetchPosts();

        // Show a success message
        dispatch(showSnackbar({ open: true, message: "Post successfully boomeranged!", severity: "success" }));
      } else {
        // Handle the case where the response is not OK
        // Show an error message
        dispatch(showSnackbar({ open: true, message: "Failed to boomerang post. Please try again later.", severity: "error" }));
      }
    } catch (error) {
      // Handle errors
      console.error("Error boomeranging post:", error);
      // Show an error message
      dispatch(showSnackbar({ open: true, message: "An error occurred while boomeranging post. Please try again later.", severity: "error" }));
    }
  };


  const patchBoomerangComment = async (commentId) => {
    try {
      const response = await fetch(
        `https://chirpskykite-server.onrender.com/posts/${postId}/comment/${commentId}/boomerang-comment`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: loggedInUserId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to boomerang the comment");
      }

      const updatedPost = await response.json();

      // Find the updated comment
      const updatedComment = updatedPost.comments.find(comment => comment._id === commentId);

      // Update the state with the new like status and count
      setIsBoomerangedComment(!isBoomerangedComment);
      setBoomerangCountComment(Object.keys(updatedComment.boomerangs).length); // Update like count

      // Dispatch the updated post after liking the comment
      dispatch(setPost({ post: updatedPost }));

    } catch (error) {
      console.error("Error Boomeranging the comment:", error.message);
    }
  };





  // COMMENTS
  const patchComment = async () => {
    if (commentContent.trim() === "") {
      // Comment is empty, handle the error or show a message to the user
      return;
    }

    const newComment = {
      userId: loggedInUserId,
      username: username,
      commentText: commentContent,
      createdAt: new Date().toISOString(),
      updatedAt: null,
      picturePath: userPicturePath,
      likes: {},
      hates: {},
      boomerangs: {},
    };

    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/comment`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: loggedInUserId,
          commentText: commentContent,
        }),
      });

      const updatedPost = await response.json();

      // Check if updatedPost.comments is an array before sorting
      const sortedComments = Array.isArray(updatedPost.comments)
        ? updatedPost.comments
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((comment) => ({
            ...comment,
            formattedTimestamp: comment.createdAt
              ? format(new Date(comment.createdAt), 'MMM dd, yyyy HH:mm')
              : '',
            formattedUpdatedAt: comment.updatedAt
              ? format(new Date(comment.updatedAt), 'MMM dd, yyyy HH:mm')
              : '',
          }))
        : [];

      // Ensure that the new comment is added to the sorted comments
      const updatedComments = [...sortedComments, newComment];

      // Update the post with sorted comments and reset comment input field
      dispatch(setPost({ post: { ...updatedPost, comments: updatedComments } }));
      setCommentContent('');

      dispatch(showSnackbar({ severity: "success", message: "Chirp Successful" }));
    } catch (error) {
      console.error("Error:", error.message);
      // Dispatch action to show error snackbar
      dispatch(showSnackbar({ severity: "error", message: "Failed to Chirp. Please try again later." }));
    }
  };


  const handleCommentChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 316) {
      setCommentContent(inputValue);
    }
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    patchComment();
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/comment/${commentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        dispatch(deleteComment({ postId, commentId }));
        dispatch(showSnackbar({ severity: "success", message: "Comment Successfully Deleted" }));
      } else {
        dispatch(showSnackbar({ severity: "error", message: "Failed to delete comment. Please try again later." }));
        console.log("Failed to delete comment.");
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: "Error deleting the comment. Please try again later." }));
      console.error("Error deleting the comment:", error);
    }
  };


  const handleEditClick = (commentText) => {
    setEditedCommentText(commentText); // Populate the input field with the current comment text
    setIsEditing(true);
  };


  // Event handler for saving edited comment
  const handleSaveClick = (commentId) => {
    // Ensure you have access to the edited comment text
    patchEditComment(postId, commentId, editedCommentText);
    setIsEditing(false);
  };



  const handleEmojiSelectEdit = (emoji) => {
    setEditedCommentText((prevContent) => prevContent + emoji.native);
    setOpenPickerEdit(false);
  };


  const patchEditComment = async (postId, commentId, commentText) => {
    // Convert postId and commentId to strings
    // postId = String(postId);
    // commentId = String(commentId);

    // console.log("postId:", postId);
    // console.log("commentId:", commentId);

    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/comment/${commentId}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ commentText }),
      });

      if (response.ok) {
        const { comment } = await response.json();
        dispatch(handleEditComment({
          postId,
          commentId,
          updatedComment: comment
        }));
        dispatch(showSnackbar({ severity: "success", message: "Comment updated successfully" }));
      } else {
        dispatch(showSnackbar({ severity: "error", message: "Failed to update comment" }));
      }
    } catch (error) {
      dispatch(showSnackbar({ severity: "error", message: "Failed to update comment" }));
      console.error("Error updating comment:", error);
    }
  };


  //----------------------------------------------------------------------------


  const handleCommentReplyChange = (e) => {
    const inputValue = e.target.value;
    const lineBreakCount = (inputValue.match(/\n/g) || []).length;
    if (inputValue.length <= 316 && lineBreakCount <= 7) {
      setReplyContent(inputValue);
    }
  };


  const handleCommentReplySubmit = (e, commentId) => {
    e.preventDefault();
    patchCommentReply(postId, commentId, replyContent);
  };





  // COMMENT REPLY
  const patchCommentReply = async (postId, commentId, replyContent) => {
    if (replyContent.trim() === "") {
      // Comment is empty, handle the error or show a message to the user
      return;
    }

    const newCommentReply = {
      userId: loggedInUserId,
      username: username, // Replace with the actual username
      replyText: replyContent,
      createdAt: new Date().toISOString(),
      picturePath: userPicturePath,
    };

    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/comment/${commentId}/reply`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: loggedInUserId,
          replyText: replyContent,
          commentId,
        }),
      });


      if (!response.ok) {
        throw new Error("Failed to add comment reply");
      }

      const updatedPost = await response.json();

      // Check if updatedPost.comments is an array before sorting
      const sortedComments = Array.isArray(updatedPost.comments)
        ? updatedPost.comments
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((comment) => ({
            ...comment,
            formattedTimestamp: comment.createdAt
              ? format(new Date(comment.createdAt), "MMM dd, yyyy HH:mm")
              : "",
          }))
        : [];

      // Ensure that the new comment is added to the sorted comments
      const updatedComments = [...sortedComments, newCommentReply];

      // Update the post with sorted comments and reset comment input field
      dispatch(setPost({ post: { ...updatedPost, comments: updatedComments } }));
      setReplyContent("");
      // Dispatch action to show success snackbar
      dispatch(showSnackbar({ severity: "success", message: "Chirp Successful" }));
    } catch (error) {
      console.error("Error:", error.message);
      // Dispatch action to show error snackbar
      dispatch(showSnackbar({ severity: "error", message: "Failed to Chirp. Please try again later." }));
    }
  };





  const handleShareButtonClick = () => {
    const postUrl = window.location.href;

    if (!postUrl) {
      dispatch(showSnackbar({ open: true, message: 'Post URL is not available.', severity: 'error' }));
      return;
    }

    navigator.clipboard.writeText(postUrl)
      .then(() => {
        dispatch(showSnackbar({ open: true, message: 'Link copied to clipboard!', severity: 'success' }));
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
        dispatch(showSnackbar({ open: true, message: 'Failed to copy link to clipboard.', severity: 'error' }));
      });
  };


  
  const handlePostEditClick = () => {
    // console.log('Entering edit mode for post description:', description); // Debugging
    setIsEditingPost(true);
    setEditedPostDescription(description);
  };

  const handlePostCancelClick = () => {
    // console.log('Cancelling edit');
    setIsEditingPost(false);
    setEditedPostDescription(description);
  };


  // console.log('Rendering PostComponent with isEditingPost:', isEditingPost);
  
  const handlePostSaveClick = async () => {

    // console.log('Saving edited post with description:', editedPostDescription); // Debugging
  if (typeof editedPostDescription !== 'string') {
    console.error('Edited post description is not a string:', editedPostDescription);
    return;
  }
    try {
      const response = await fetch(`https://chirpskykite-server.onrender.com/posts/${postId}/edit-post`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ description: editedPostDescription }),
      });

      if (response.ok) {
        const updatedPost = await response.json();
        // console.log('Post updated successfully:', updatedPost);
        dispatch(handleEditPost({ postId, updatedPost }));
        dispatch(showSnackbar({ severity: "success", message: "Post updated successfully" }));
        setIsEditingPost(false);
      } else {
        console.error('Failed to update post');
        dispatch(showSnackbar({ severity: "error", message: "Failed to update post" }));
      }
    } catch (error) {
      console.error("Error updating post:", error);
      dispatch(showSnackbar({ severity: "error", message: "Failed to update post" }));
    }
  };



  // Add useEffect to log the comments
  // useEffect(() => {
  //   console.log(comments); // Check if comments include updatedAt
  // }, [comments])

  return (
    <div>
      {message && (
        <div
          className="message"
          style={{
            backgroundColor: "red",
            fontSize: "26px",
            color: "black",
            padding: "10px",
            border: "12px solid blue",
            borderRadius: "5px",
            margin: "10px 0",
          }}
        >
          {message}
        </div>
      )}


      <Box className={`post-widget-container ${isFlipped ? 'flipped' : ''}`} m="2rem 0">
        <WidgetWrapper m="2rem 0" style={{ height: isFlipped ? '720px' : 'auto' }}>
          {isFlipped ? (
            <div style={{ height: '100%', backgroundColor: 'skyblue', padding: '2rem' }}>
              {/* Add styling for the blank canvas */}
              Blank Canvas
            </div>
          ) : (
            <div>


              <Friend
                friendId={postUserId}
                name={name}
                username={username}
                subtitle={location}
                userPicturePath={userPicturePath}
                postId={postId}
              />

{loggedInUserId === postUserId && (
        isEditingPost ? (
          <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
            <IconButton onClick={handlePostSaveClick}>
              <DoneIcon />
            </IconButton>
            <IconButton onClick={handlePostCancelClick}>
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
            <IconButton onClick={handlePostEditClick}
            sx={{ position: 'relative', top: '-71px', left: '-50px', fontSize: "3rem", color: "#1976d2", }}
            >
              <EditOutlined />
            </IconButton>
          </Box>
        )
      )}



              {/* <Button onClick={handleFlip}>Flip</Button>  */}


              <Typography color={main} sx={{
                mt: "1rem",
                width: "100%",
                padding: "1rem 2rem",
                fontSize: "1.2rem",
                whiteSpace: "pre-wrap",  // Allow text wrapping
                wordWrap: "break-word",
                // Break words when necessary


              }}>
                {isEditingPost ? (
                  <TextField
                  value={typeof editedPostDescription === 'string' ? editedPostDescription : ''}
                    onChange={(e) => {
                      const value = e.target.value;
                      const lineBreakCount = (value.match(/\n/g) || []).length;
                      if (value.length <= 316 && lineBreakCount <= 7) {
                        setEditedPostDescription(value);
                      }
                    }}
                    fullWidth
                    multiline
                    maxRows={4}
                    variant="outlined"
                    inputProps={{ maxLength: 316 }}
                    helperText={
                      <span style={{
                        color: editedPostDescription.length >= 316 ? '#8a0303' : 'green',
                        fontSize: '1rem'
                      }}>
                        {`${316 - editedPostDescription.length} Kharacters remaining`}
                      </span>
                    }
                    sx={{ mt: 2 }}
                  />
                ) : (
                  description
                )}
              </Typography>

  

              {picturePath && (
                <img
                  onClick={handleImageDialogOpen}
                  width="100%"
                  height="auto"
                  alt="post"
                  style={{ borderRadius: "0.75rem", marginTop: "0.75rem", cursor: "pointer" }}
                  src={`${picturePath}`}
                />
              )}

              {videoPath && (
                <video
                  onClick={handleVideoClick}
                  width="100%"
                  height="auto"
                  alt="video not available!"
                  style={{ borderRadius: "0.75rem", marginTop: "0.75rem", cursor: "pointer" }}
                  controls
                  autoplay
                  muted
                >
                  <source src={`${videoPath}`} />
                  Your browser does not support the video format.
                </video>
              )}

              {/* Image Dialog */}
              <Dialog open={isImageDialogOpen} onClose={handleImageDialogClose} sx={{ overflow: "hidden" }} onWheel={handleImageWheel}>
                <DialogContent sx={{ maxWidth: '90vw', maxHeight: '90vh', overflow: "hidden" }}>
                  <Box position="absolute" top={0} right={0}>
                    <Tooltip title="Close">
                      <IconButton onClick={handleImageDialogClose}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <img
                    width="100%"
                    height="auto"
                    alt="post"
                    src={`${picturePath}`}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      height: '100%',
                      transform: `scale(${zoomLevel})`, // Apply zoom level
                    }}
                  />
                </DialogContent>
              </Dialog>

              {/* Video Dialog */}
              <Dialog open={isVideoDialogOpen} onClose={handleVideoDialogClose}>
                <DialogContent sx={{ maxWidth: '90vw', maxHeight: '90vh' }}>
                  <Box position="absolute" top={0} right={0}>
                    <Tooltip title="Close">
                      <IconButton onClick={handleVideoDialogClose}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <video
                    width="100%"
                    height="auto"
                    alt="video not available!"
                    controls
                    autoplay
                    muted
                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                  >
                    <source src={`${videoPath}`} />
                    Your browser does not support the video format.
                  </video>
                </DialogContent>
              </Dialog>



              {/* Display the formatted timestamp */}
              <Typography color="red" sx={{ mt: "0.5rem", textAlign: "center" }}>
                {formattedTimestamp && <span>{formattedTimestamp}</span>}
              </Typography>
              <FlexBetween mt="0.25rem" gap="1rem">
                {/* Love */}
                <FlexBetween>
                  <Tooltip title="Love">
                    <IconButton onClick={patchLike}>
                      {isLikedPost ? (
                        <FavoriteOutlined sx={{ color: "red", "&:hover": { color: "#8a0303", cursor: "pointer" } }} />
                      ) : (
                        <FavoriteBorderOutlined />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Typography>{likeCountPost}</Typography>
                </FlexBetween>

                {/* Hate */}
                <FlexBetween>
                  <Tooltip title="Hate">
                    <IconButton onClick={patchHate}>
                      {isHated ? (
                        <ThumbDownOutlined sx={{ color: "brown", "&:hover": { color: "#77971b", cursor: "pointer" } }} />
                      ) : (
                        <ThumbDown />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Typography>{hateCount}</Typography>
                </FlexBetween>

                {/* Boomerang */}
                <FlexBetween>
                  <Tooltip title="Boomerang">
                    <IconButton onClick={patchBoomerang}>
                      {isBoomeranged ? (
                        <ThreeSixtyOutlined sx={{ color: primary, "&:hover": { color: "#0275F4" } }} />
                      ) : (
                        <ThreeSixty />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Typography>{boomerangCount}</Typography>
                </FlexBetween>
                {/* Speak Your Peace */}
                <FlexBetween>
                  <Tooltip title="Speak Your Peace">
                    <IconButton onClick={() => setIsComments(!isComments)}>
                      <ChatBubbleOutlineOutlined />
                    </IconButton>
                  </Tooltip>
                  <Typography>{commentCount}</Typography>
                </FlexBetween>
              </FlexBetween>


              <Divider />
              <FlexBetween justifyContent="center" marginRight="3rem">
                <Tooltip title="Share">
                  <IconButton id="shareButton" onClick={handleShareButtonClick}>
                    <ShareOutlined />
                  </IconButton>
                </Tooltip>
              </FlexBetween>



              <Divider />
              <form onSubmit={handleCommentSubmit}>
                <TextField
                  label="Leave a comment"
                  variant="outlined"
                  value={commentContent}
                  onChange={handleCommentChange}
                  fullWidth
                  margin="dense"
                  multiline
                  maxRows={4}
                  inputProps={{ maxLength: 316 }}
                  sx={{
                    width: "100%",
                    padding: "0.2rem 0.2rem",
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                  }}
                  size="small"
                  InputProps={{  // InputProps instead of endAdornment
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setOpenPicker((prev) => !prev);
                          }}
                          sx={{
                            backgroundColor: '#8a0303',
                            borderRadius: "50%",
                            color: palette.common.white,
                          }}
                        >
                          <MaskHappy />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Box sx={{ display: openPicker ? "inline" : "none", zIndex: 10, position: "fixed", top: 80, right: 20 }}>
                  <EmojiPicker
                    setOpenPicker={() => { }}
                    data={data}
                    onEmojiSelect={handleEmojiSelect}
                  />
                </Box>


                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "10px", marginBottom: "20px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fontFamily="mortal kombat 4"
                    type="submit"
                    sx={{
                      "&:hover": { color: "#8a0303", backgroundColor: "skyblue" }
                    }}
                  >
                    Chirp Comment
                  </Button>
                </Box>
              </form>
              {isComments && (
                <Box mt=".5rem" sx={{ maxHeight: "400px", overflowY: "auto" }}>
                  {comments
                    .slice()
                    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    .map((comment, i) => (
                      <Box key={`${name}-${i}`}>
                        <Divider />
                        {currentUserId === comment.userId && ( // Check if current user is the author

                          <Box display="flex" justifyContent="flex-end" alignItems="center" ml="180px" pt=".2rem">
                            {isEditing ? (
                              <>
                                <IconButton onClick={() => handleSaveClick(comment._id)}>
                                  <DoneIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => setIsEditing(false)} // Cancel editing
                                  sx={{ fontSize: "3rem", color: "#8a0303" }}
                                >
                                  <CloseIcon fontSize="large" />
                                </IconButton>
                              </>
                            ) : (
                              <IconButton onClick={() => handleEditClick(comment.commentText)} sx={{ fontSize: "3rem", color: "#1976d2", mr: "0.5rem" }}>
                                <EditOutlined fontSize="large" />
                              </IconButton>
                            )}

                            <IconButton
                              onClick={() => handleDeleteComment(comment._id)}
                              sx={{ fontSize: "3rem", color: "#8a0303" }}
                            >
                              <DeleteOutline fontSize="large" />
                            </IconButton>
                          </Box>
                        )}
                        {isEditing && (
                          <TextField
                            value={editedCommentText}
                            onChange={(e) => {
                              const value = e.target.value;
                              const lineBreakCount = (value.match(/\n/g) || []).length;
                              if (value.length <= 316 && lineBreakCount <= 7) {
                                setEditedCommentText(value);
                              }
                            }}
                            fullWidth
                            multiline
                            maxRows={3}
                            variant="outlined"
                            inputProps={{ maxLength: 316 }} // Enforce max length
                            helperText={
                              <span style={{
                                color: editedCommentText.length >= 316 ? '#8a0303' : 'green',
                                fontSize: '1rem'
                              }}>
                                {`${316 - editedCommentText.length} Kharacters remaining`}
                              </span>
                            }
                            sx={{ mt: 2 }}

                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setOpenPickerEdit((prev) => !prev)}
                                    sx={{
                                      backgroundColor: '#8a0303',
                                      borderRadius: "50%",
                                      color: 'white',
                                    }}
                                  >
                                    <MaskHappy />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}

                        {openPickerEdit && (
                          <Box sx={{ zIndex: 10, position: "fixed", top: 80, right: 20 }}>
                            <EmojiPicker
                              setOpenPickerEdit={() => { }}
                              data={data}
                              onEmojiSelect={handleEmojiSelectEdit}
                            />
                          </Box>
                        )}
                        <FlexBetween>
                          <Box display="flex" alignItems="center">

                            <UserImage
                              image={comment.picturePath}
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: "50%",

                              }}
                            />

                            <Typography sx={{ mt: "0.7rem 0", p: ".3rem", maxWidth: "100%", wordWrap: "break-word" }}>
                              <Link to={`/profile/${comment.userId}`} style={{ textDecoration: "none", fontSize: "1.5rem" }}>
                                <Typography color="skyblue" sx={{ mt: "-.3rem", textAlign: "center" }}>
                                  {comment.createdAt && (
                                    <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Created: {format(new Date(comment.createdAt), "MMM dd, yyyy HH:mm")}</span>
                                  )}
                                </Typography>
                                <Typography color="#8a0303" sx={{ mt: ".4rem", textAlign: "center" }}>
                                  {comment.updatedAt && (
                                    <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>Edited: {format(new Date(comment.updatedAt), "MMM dd, yyyy HH:mm")}</span>
                                  )}
                                </Typography>

                                <strong>
                                  <span style={{ color: "yourDesiredColor" }}>@</span>{comment.username}
                                </strong>
                              </Link> - {comment.commentText}
                            </Typography>
                          </Box>
                        </FlexBetween>
                        <Divider />

                        {/* comments sections */}
                        <FlexBetween mt="0.25rem">
                          <FlexBetween gap="1rem">
                            <FlexBetween gap="0.3rem">
                              <Tooltip title="Love">
                                <IconButton onClick={() => patchLikeComment(comment._id)}>
                                  {comment.likes[loggedInUserId] ? (
                                    <FavoriteOutlined sx={{ color: "red", "&:hover": { color: "#8a0303", cursor: "pointer" } }} />
                                  ) : (
                                    <FavoriteBorderOutlined />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Typography>{Object.keys(comment.likes).length}</Typography>
                            </FlexBetween>


                            <FlexBetween gap="0.3rem">
                              <Tooltip title="Shit Head">
                                <IconButton onClick={() => patchHateComment(comment._id)} >
                                  {comment.hates[loggedInUserId] ? (
                                    <Icon
                                      className="shithead" icon="mdi:emoticon-poop" color="#964b00" hFlip={true} width="30" height="30"
                                    />


                                  ) : (
                                    <Icon className="shithead" icon="mdi:emoticon-poop" color="#ad900d" hFlip={true} width="30" height="30"
                                    />


                                  )}
                                </IconButton>
                              </Tooltip>
                              <Typography>{Object.keys(comment.hates).length}</Typography>
                            </FlexBetween>



                            <FlexBetween gap="0.3rem">
                              <Tooltip title="Boomerang">
                                <IconButton onClick={() => patchBoomerangComment(comment._id)}>
                                  {comment.boomerangs[loggedInUserId] ? (
                                    <ThreeSixtyOutlined sx={{ color: primary, "&:hover": { color: "#0275F4" } }} />
                                  ) : (
                                    <ThreeSixty />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Typography>{Object.keys(comment.boomerangs).length}</Typography>
                            </FlexBetween>

                            <FlexBetween gap="0.3rem">
                              <Tooltip title="WOW! Words Of Wisdom">
                                <IconButton onClick={() => setIsCommentsReply(prev => prev === comment._id ? null : comment._id)}>
                                  <ChatBubbleOutlineOutlined style={{ color: comment.replies.length > 0 ? 'skyblue' : 'black' }} />
                                </IconButton>
                              </Tooltip>
                              <Typography>Replies: {comment.replies.length}</Typography>
                            </FlexBetween>
                          </FlexBetween>

                          <FlexBetween gap="0.3rem">
                            <Typography>{isCommentsReply && isCommentsReply !== comment._id}</Typography>
                          </FlexBetween>
                        </FlexBetween>

                        {/* COMMENT REPLY BOX */}
                        <Divider />

                        {/* Render Replies */}
                        {isCommentsReply === comment._id && (
                          <Box mt={1} ml={.1}>
                            {comment.replies.map((reply) => (
                              <Box key={reply._id} mb={1}>
                                <FlexBetween alignItems="center" justifyContent="flex-start">
                                  <UserImage
                                    image={reply.picturePath}
                                    style={{
                                      width: 25,
                                      height: 25,
                                      borderRadius: "50%",
                                    }}
                                  />
                                  <Box flex="1">
                                    <Box>
                                      <Link to={`/profile/${reply.userId}`} style={{ textDecoration: "none" }}>
                                        <Typography color="skyblue" sx={{ fontSize: "1.2rem" }}>
                                          {reply.username}
                                        </Typography>
                                      </Link>
                                    </Box>
                                    <Typography color="#8a0303" sx={{ fontSize: "1rem", mt: 0.5 }}>
                                      {reply.replyText}
                                    </Typography>
                                    {/* Timestamp */}
                                    <Typography color="textSecondary" sx={{ fontSize: "0.8rem", ml: "auto" }}>
                                      {new Date(reply.createdAt).toLocaleString()}
                                    </Typography>
                                  </Box>
                                </FlexBetween>
                              </Box>
                            ))}

                            {/* Reply Form */}
                            <form onSubmit={(e) => handleCommentReplySubmit(e, comment._id)}>
                              <TextField
                                label="Speak Now Or Forever Hold Your Peace"
                                variant="outlined"
                                value={replyContent}
                                onChange={handleCommentReplyChange}
                                fullWidth
                                margin="dense"
                                multiline
                                maxRows={4}
                                inputProps={{ maxLength: 316 }}
                                size="small"
                                sx={{ mt: 1 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => setOpenPickerEdit((prev) => !prev)}
                                        sx={{
                                          backgroundColor: '#8a0303',
                                          borderRadius: "50%",
                                          color: 'white',
                                        }}
                                      >
                                        <MaskHappy />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              {/* Character counter */}
                              <Box sx={{ mt: 1 }}>
                                <Typography variant="body2" color={replyContent.length >= 316 ? '#8a0303' : 'green'} sx={{ fontSize: '1rem' }}>
                                  {`${316 - replyContent.length} Kharacters remaining`}
                                </Typography>
                              </Box>
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                sx={{
                                  "&:hover": { color: "#8a0303", backgroundColor: "skyblue" },
                                  float: "right", // This will move the button to the right
                                  mt: 1
                                }}
                              >
                                Chirp Reply
                              </Button>
                            </form>
                          </Box>
                        )}

                      </Box>
                    ))}
                </Box>
              )}

            </div>)}

        </WidgetWrapper>

      </Box>

    </div >

  );
};

export default PostWidget;